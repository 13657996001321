import React, { useState } from 'react';
import Container from '../container';
import Text from '../text';
import AuthService from '../../services/auth';
import styles from './styles.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCar, faCarSide, faDollar, faInfo, faInfoCircle, faLocationXmark, faMoneyBillWave, faUser, faUserGear, faXmark, faAnalytics, faSignOut } from '@fortawesome/pro-light-svg-icons';
import { NavLink, useNavigate, useNavigation } from 'react-router-dom';
import logo from '../../assets/logo/4.png'


type TypeNavbarCopmponentProps = {
  children?: React.ReactNode;
  className?: string
};

const NavbarCopmponent: React.FC<TypeNavbarCopmponentProps> = (props) => {
  const navigate = useNavigate();
  const links = [
    {
      title: 'Dashboard',
      icon: faAnalytics,
      items: [
        {
          text: 'Home',
          onClick: () => {
            setIsOpen(false)
            navigate('/');
          },
        },
        {
          text: 'Analytics',
          onClick: () => {
            setIsOpen(false)
            navigate('/analytics');
          },
        },
      ]
    },
    {
      title: 'Admin',
      icon: faUserGear,
      items: [
        {
          text: 'Admin',
          onClick: () => {
            setIsOpen(false)
            navigate('/admins');
          },
        },
      ]
    },
    {
      title: 'Pelanggan',
      icon: faUser,
      items: [
        {
          text: 'Users',
          onClick: () => {
            setIsOpen(false)
            navigate('/customers');
          },
        },
        {
          text: 'Export Users',
          onClick: () => {
            setIsOpen(false)
            navigate('/customers/list_export');
          },
        },
        /*{
          text: 'Tambah Pelanggan',
          onClick: () => {
            setIsOpen(false)
            navigate('/customers');
          },
        }*/
      ]
    },
    {
      // title: 'Driver',
      icon: faCarSide,
      items: [
        {
          text: 'Driver',
          onClick: () => {
            setIsOpen(false)
            navigate('/drivers');
          },
        },
        /*{
          text: 'Tambah Driver',
          onClick: () => {
            setIsOpen(false)
            navigate('/drivers');
          },
        }*/
      ]
    },
    {
      title: 'Pesanan',
      icon: faLocationXmark,
      items: [
        /*{
          text: 'Buat Pesanan',
          onClick: () => {
            setIsOpen(false)
            navigate('/orders/create');
          },
        },*/
        {
          text: 'Pesanan',
          onClick: () => {
            setIsOpen(false)
            navigate('/orders');
          },
        }
      ]
    },
    {
      title: 'Keuangan',
      icon: faMoneyBillWave,
      items: [
        {
          text: 'History',
          onClick: () => {
            setIsOpen(false)
            navigate('/drivers/wallet_histories');
          },
        },
      ]
    },
    {
      title: 'Konfigurasi',
      icon: faInfoCircle,
      items: [
        {
          text: 'Daftar Zona',
          onClick: () => {
            setIsOpen(false)
            navigate('/zones');
          },
        },
        {
          text: 'Daftar Area',
          onClick: () => {
            setIsOpen(false)
            navigate('/areas');
          },
        }
      ]
    },
    {
      title: 'Keluar',
      icon: faSignOut,
      items: [
        {
          text: 'Sign Out',
          onClick: () => {
            // setIsOpen(false);
            AuthService.logout();
          },
        }
        
      ]
    },
  ]

  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Container direction='column' className={`${styles.container} ${props.className}`}>
      <Container
        direction={'row'}
        justifyContent='space-between'
        alignItems='center'
        className={styles.navbar}
      >
        <NavLink to={"/"}><img src={logo} alt="logo" style={{maxWidth: '100px'}} /></NavLink>
        <FontAwesomeIcon icon={isOpen ? faXmark : faBars} size='lg' className={styles.barsIcon} onClick={() => setIsOpen(!isOpen)} />
      </Container>
      {isOpen &&
        <Container className={styles.items} gap={20}>
          {links.map((link, i) => (
            <Container key={i} direction='row' gap={10}>
              <FontAwesomeIcon icon={link.icon} size='sm' style={{ paddingTop: 4,color:'#fff' }} />
              <Container gap={10} style={{width:'100%'}}>
                <Text size={'sm'} style={{color:'rgba(255,255,255,0.6)',fontSize:10}}>{link.title}</Text>
                {link.items.map((item, ix) => (
                  <Text
                    style={{
                      cursor: 'pointer',
                      userSelect: 'none',
                      color:'#fff',
                      fontSize:12
                    }}
                    key={ix}
                    // size={'3xl'}
                    weight='medium'
                    onClick={item.onClick}
                  >{item.text}</Text>
                ))}
                <hr style={{marginLeft:0,borderColor:'rgba(255,255,255,0.1)'}}/>
              </Container>
            </Container>
          ))}
        </Container>
      }
    </Container >
  )
};

export default NavbarCopmponent;