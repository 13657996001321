import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss';
import ZoneForm from '../../../components/zones/form';

type UpdateZonePageProps = {
    children?: React.ReactNode;
};

const UpdateZonePage: React.FC<UpdateZonePageProps> = (props) => {
    const { zoneId } = useParams();
    return <Container className={styles.container} gap={20}>
      <Text size={'xl'} weight='bold'>Update Zona</Text>
      <ZoneForm zoneId={Number(zoneId)} />
    </Container>;
};

export default UpdateZonePage;