import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faCog, faInfoCircle, faSignOut, faCartCircleXmark, faChartArea, faUsers, faUserAlt, faUserFriends, faPhoneAlt, faHistory, faSquare, faAreaChart, faCircle } from '@fortawesome/pro-light-svg-icons';
import GridThumbnailMenu from './GridThumbnailMenu';
import styles from './GridThumbnailMenu.module.scss';
import AuthService from '../../../services/auth';
import { NavLink, useNavigate, useNavigation } from 'react-router-dom';
import Text from '../../../components/text';
import Cookies from 'js-cookie';
import { fontWeight } from '@mui/system';
const handleLogout = () => {
  AuthService.logout();
};


type TypeNavbarCopmponentProps = {
  children?: React.ReactNode;
  className?: string
};

const Index: React.FC<TypeNavbarCopmponentProps> = (props) => {


  let navigate = useNavigate();


  const items = [
    // {
    //   icon: <FontAwesomeIcon className={styles.icon} icon={faHome} size="2x" />,
    //   title: 'Home',
    //   onClick: () => navigate('/')
    // },
    {
      icon: <FontAwesomeIcon className={styles.icon} icon={faChartArea} size="2x" />,
      title: 'Analytics',
      onClick: () => navigate('/analytics')
    },
    {
      icon: <FontAwesomeIcon className={styles.icon} icon={faPhoneAlt} size="2x" />,
      title: 'Pesanan',
      onClick: () => navigate('/orders')
    },
    {
      icon: <FontAwesomeIcon className={styles.icon} icon={faHistory} size="2x" />,
      title: 'Keuangan',
      onClick: () => navigate('/drivers/wallet_histories')
    },
    
  ];

  const items2 = [
    {
      icon: <FontAwesomeIcon className={styles.icon} icon={faUser} size="2x" />,
      title: 'Root',
      onClick: () => navigate('/admins')
    },
    {
      icon: <FontAwesomeIcon className={styles.icon} icon={faUsers} size="2x" />,
      title: 'Users',
      onClick: () => navigate('/customers')
    },
    {
      icon: <FontAwesomeIcon className={styles.icon} icon={faUserFriends} size="2x" />,
      title: 'Mitra',
      onClick: () => navigate('/drivers')
    },
  ];
  const items3 = [
    {
      icon: <FontAwesomeIcon className={styles.icon} icon={faSignOut} size="2x" />,
      title: 'Logout',
      onClick: () => AuthService.logout(),
    },
  ];
  
  const items4 = [
    {
      icon: <FontAwesomeIcon className={styles.icon} icon={faCircle} size="2x" />,
      title: 'Area',
      onClick: () => navigate('/areas')
    },
    {
      icon: <FontAwesomeIcon className={styles.icon} icon={faSquare} size="2x" />,
      title: 'Zonasi',
      onClick: () => navigate('/zones')
    },
  ];

  
  return (
    <div className={styles.container}>
      <div className={styles.bigView}>
        <Text style={{fontSize:23, display:'inline-flex'}}>Selamat datang kembali,  <Text style={{fontWeight:'normal', marginLeft:10, textDecoration:'underline'}}>{Cookies.get('name')}</Text></Text>
      </div>
      <Text style={{paddingLeft:20, paddingTop:20}}>General Settings</Text>
      <GridThumbnailMenu items={items} />

      <Text style={{paddingLeft:20, paddingTop:20}}>Account Settings</Text>
      <GridThumbnailMenu items={items2} />
      
      <Text style={{paddingLeft:20, paddingTop:20}}>Area & Zonasi</Text>
      <GridThumbnailMenu items={items4} />

      <Text style={{paddingLeft:20, paddingTop:20}}>End Session</Text>
      <GridThumbnailMenu items={items3} />
    </div>
    
  );
};


export default Index;