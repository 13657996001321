import React, { useEffect } from 'react';
import Text from '../../text';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Spinner, Modal, ModalContent, ModalHeader, ModalBody, Button } from "@nextui-org/react";
import { IStationAreaResourceShortProps } from '../../../props/stations/area';
import { useNavigate } from 'react-router-dom';
import { IStationQueueResourceShortProps } from '../../../props/stations/queue';
import StationQueueService from '../../../services/station/queue';
import moment from 'moment';
import styles from '../../../assets/css/styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/pro-light-svg-icons';


type AreaQueueListProps = {
	children?: React.ReactNode;
	area: IStationAreaResourceShortProps;
	onClose(): void;
};

const AreaQueueList: React.FC<AreaQueueListProps> = (props) => {
	const navigate = useNavigate();
	const { area } = props;

	const [queues, setQueues] = React.useState<IStationQueueResourceShortProps[]>([]);
	const [loaded, setLoaded] = React.useState<boolean>(false);

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		setLoaded(false);
		const _queues = await StationQueueService.retrieve(area.id);
		setQueues(_queues.result);
		setLoaded(true);
	}

	return (
		<Modal size='lg' style={{paddingTop:20, paddingBottom:50}} isOpen={true} onOpenChange={(isOpen) => { if (!isOpen) { props.onClose() } }}>
			<ModalContent >
				<ModalHeader className="flex flex-col gap-1">
					Urutan Antrian @ {area.name}
					<Text style={{fontSize:11, fontWeight:'normal', marginTop:-10}}>Refresh secara berkala untuk melihat antrian ini secara ter-update.</Text>
					<Button style={{backgroundColor:'#ef4727',color:'#fff'}} onPress={()=>init()}>
						<FontAwesomeIcon icon={faRefresh} size='sm' color='#fff'/> Refresh Now
					</Button>
					</ModalHeader>
				<ModalBody>
					{!loaded ? <Spinner color='danger' /> : null}
					{loaded && area ? <>
						<Table aria-label="Daftar area">
							<TableHeader>
								<TableColumn width={30}>No.</TableColumn>
								<TableColumn>Driver</TableColumn>
								<TableColumn width={100}>Jam Absen</TableColumn>
							</TableHeader>
							<TableBody isLoading={!loaded} loadingContent={<Spinner />} emptyContent={<Text>Belum ada antrian</Text>}>
								{queues.map((queue, idx) => {
									return <TableRow key={area.id}>
										<TableCell>
											<Text size='base' weight='medium'>{(idx || 0) + 1}</Text>
										</TableCell>
										<TableCell>
											<Text size='base' weight='medium'>{queue.driver.first_name}{queue.driver.last_name ? " " + queue.driver.last_name : ""}</Text>
										</TableCell>
										<TableCell>
											<Text size='base' weight='medium'>{moment(queue.checkin_time).format("hh:mm")}</Text>
											<Text size='tiny' weight='medium' style={{color:'#ccc'}}>{moment(queue.checkin_time).format("DD MMM")}</Text>
										</TableCell>
									</TableRow>;
								})}
							</TableBody>
						</Table>
					</> : null}
				</ModalBody>
			</ModalContent>
		</Modal>
	)
};

export default AreaQueueList;