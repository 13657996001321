import React from 'react';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss'
import { Button, Input } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';
import { NavLink } from 'react-router-dom';
import ListArea from '../../../components/areas/list';

type AreaPageProps = {
  children?: React.ReactNode;
};

const AreaPage: React.FC<AreaPageProps> = (props) => {
  return (
    <Container className={styles.container} gap={20} style={{marginBottom:100}}>
      <Text size={'xl'} weight='bold'>Area</Text>
      <ListArea />
    </Container>
  )
};

export default AreaPage;