import React, { useEffect, useState } from 'react';
import Text from '../../text';
import { faChevronDown, faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Spinner, Input, Button, Image} from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../../buttons/dropdown';
import Container from '../../container';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDriverResourceShort } from '../../../props/drivers';
import DriverService from '../../../services/drivers';
import NumberService from '../../../services/general/number';
import GlobalConfig from '../../../config';


type TypeListDriverProps = {
	children?: React.ReactNode;
};

const ListDriver: React.FC<TypeListDriverProps> = (props) => {
	const navigate = useNavigate();

	const [drivers, setDrivers] = React.useState<IDriverResourceShort[]>([]);
	const [loaded, setLoaded] = React.useState<boolean>(false);
	const [search, setSearch] = React.useState<string>();

	const actions: DropdownButtonSectionProps[] = [
		{
			key: 'details',
			showDivider: false,
			items: [
				{ key: 'view', text: 'Lihat detail', onPress: (driver: IDriverResourceShort) => { navigate(`/drivers/${driver.id}/details`); } },
				{ key: 'topup', text: 'Top-up saldo', onPress: (driver: IDriverResourceShort) => { navigate(`/drivers/${driver.id}/topup/request`); } },
				{ key: 'withdraw', text: 'Tarik saldo', onPress: (driver: IDriverResourceShort) => { navigate(`/drivers/${driver.id}/withdraw`); } },
				{ key: 'setStatus', text: 'Banned/Unbanned', onPress: (driver: IDriverResourceShort) => {
					const fd = new FormData
					fd.append('driver_id', driver.id + "");
					if(driver.status){
						fd.append('status', "0")
					}else{
						fd.append('status', "1")
					}
					statusDriver(driver.id, fd)
				  }
				},
			]
		},
	];

	const statusDriver = async (id: number,fd: FormData) => {
		try {
			await DriverService.updateStatus(id, fd)
			getDrivers()
			setLoaded(true)
		} catch (error) {
			setLoaded(true)
		}
	}

	useEffect(() => {
		getDrivers()
		return () => { }
	}, [search])

	const getDrivers = async () => {
		const qs: string[] = [];
		if (search && search.trim() !== '') { qs.push("search=" + search); }
		try {
			const items = await DriverService.retrieve(qs.join(';'))
			setDrivers(items)
			setLoaded(true)
		} catch (error) {

		}
	}

	return <Container gap={20}>
		<Container direction='row' gap={10} style={{ zIndex: 1,width:'100%'}}>
			<Input
				placeholder="Cari mitra ..."
				endContent={
					<FontAwesomeIcon icon={faSearch} />
				}
				color='warning'
				style={{ zIndex: 1 }}
				value={search}
				onValueChange={(value) => {
					setSearch(value);
				}}
			/>
			{/*<NavLink to={"/drivers/create"}>
				<Button isIconOnly color='primary'>
					<FontAwesomeIcon icon={faPlus} />
				</Button>
			</NavLink>*/}
		</Container>
		<Table aria-label="Daftar mitra" isStriped >
			<TableHeader>
				<TableColumn>No</TableColumn>
				{/* <TableColumn>Foto</TableColumn> */}
				<TableColumn>Nama</TableColumn>
				<TableColumn>No HP</TableColumn>
				<TableColumn>Saldo</TableColumn>
				<TableColumn>Tabungan</TableColumn>
				<TableColumn>Status Online</TableColumn>
				<TableColumn>Status</TableColumn>
				<TableColumn width={40}>Action</TableColumn>
			</TableHeader>
			<TableBody isLoading={!loaded} loadingContent={<Spinner />} emptyContent={<Text>Belum ada driver terdaftar</Text>}>
				{drivers.map((driver, index) => {
					return <TableRow key={driver.id}>
						<TableCell aria-label='no'>
							<Text size='base' weight='medium'>{index+1}</Text>
						</TableCell>
						{/* <TableCell aria-label='first_name'>
							<Container direction='row' alignItems='center' gap={5}>
							<Image
								width={50}
								height={50}
								alt="profile_picture"
								src={GlobalConfig.assetHostname + '/storage/' + driver.profile_picture}
							/>
							</Container>
						</TableCell> */}
						<TableCell aria-label='first_name'>
							<Text size='base' weight='medium'>{driver.first_name} {driver.last_name || ""}</Text>
						</TableCell>
						<TableCell aria-label='no_hp'>
							<Text size='base' weight='medium'>{driver.phone_with_code}</Text>
						</TableCell>
						<TableCell aria-label='status'>
							<Text size='base' weight='medium'>Rp. {NumberService.getNumberWithSeparator(driver.wallet)}</Text>
						</TableCell>
						<TableCell aria-label='status'>
							<Text size='base' weight='medium'>Rp. {NumberService.getNumberWithSeparator(driver.tabungan)}</Text>
						</TableCell>
						<TableCell aria-label='status_online'>
							<Text size='base' weight='medium' style={{ color: driver.online_status === 1 ? '#007200' : '#B02E2C' }}>{driver.online_status === 1 ? 'Online' : 'Offline'}</Text>
						</TableCell>
						<TableCell aria-label='status'>
							<Text size='base' weight='medium' style={{ color: driver.status === 1 ? '#007200' : '#B02E2C' }}>{driver.status === 1 ? 'Aktif' : 'Banned'}</Text>
						</TableCell>
						<TableCell key={'action'} aria-label='actions'>
							<DropdownButton icon={faChevronDown} sections={actions} backdrop='blur' data={driver} />
						</TableCell>
					</TableRow>
				})}
			</TableBody>
		</Table>
	</Container>;
};

export default ListDriver;