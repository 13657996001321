import React, { useEffect } from 'react';
import Text from '../../text';
import { faArrowLeft, faChevronDown, faEdit, faEye, faList, faListAlt, faListCheck, faToggleOff, faToggleOn } from '@fortawesome/pro-light-svg-icons';
import { Spinner, Divider, Chip, Button, Tooltip } from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../../buttons/dropdown';
import Container from '../../container';
import styles from './styles.module.scss';
import { Circle, GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import StationAreaService from '../../../services/station/area';
import { useNavigate } from 'react-router-dom';
import GlobalConfig from '../../../config';
import { IStationAreaResourceShortProps } from '../../../props/stations/area';
import AreaPriceList from '../prices/list';
import InactivateArea from '../modals/inactivate';
import ActivateArea from '../modals/activate';
import AreaQueueList from '../modals/queues';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { green } from '@mui/material/colors';


type AreaDetailsProps = {
    areaId: number;
};

const AreaDetails: React.FC<AreaDetailsProps> = (props) => {
    const navigate = useNavigate();

    const mapStatus = useJsApiLoader(GlobalConfig.googleMapConfig as any);

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [area, setArea] = React.useState<IStationAreaResourceShortProps | undefined>();
    const [activeModal, setActiveModal] = React.useState<string>("");

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const _area = await StationAreaService.read(props.areaId);
        setArea(_area.result);
        setLoaded(true);
    }

    const getActions = (): DropdownButtonSectionProps[] => {
        const actions: DropdownButtonSectionProps[] = [
            {
                key: 'details',
                showDivider: true,
                items: [
                    { key: 'update', text: 'Update', onPress: (area: IStationAreaResourceShortProps) => { navigate(`/areas/${area.id}/update`); } }
                ]
            },
            {
                key: 'queues',
                showDivider: true,
                items: [
                    { key: 'queue', text: 'Lihat antrian', onPress: () => { setActiveModal('queues') } }
                ]
            }
        ];

        if (area && area.is_active) {
            actions.push({
                key: 'deletion',
                items: [
                    { key: 'inactivate', text: 'Non-aktifkan area', variant: 'danger', onPress: () => { setActiveModal('inactivate') } }
                ]
            });
        } else {
            actions.push({
                key: 'activation',
                items: [
                    { key: 'activate', text: 'Aktifkan area', variant: 'success', onPress: () => { setActiveModal('activate') } }
                ]
            });
        }

        return actions;
    }

    const _onCloseModal = (refresh?: boolean) => {
        setActiveModal("");

        if (refresh) {
            setLoaded(false);
            init();
        }
    }

    return <Container className={styles.container} gap={20} style={{
            backgroundColor: 'white',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 0,
            marginBottom:100
            // alignItems: 'center',
            // justifyContent: 'center',
            // height: '80vh',
        }}>
        {!loaded ? <Spinner label='Memuat detail ...' /> : null}
        {loaded && area ? <>
            <Container style={{display:'flex', justifyContent:'start',alignItems:'start'}}>
        <Button onClick={() => {navigate(-1)}} style={{backgroundColor:'#f2f2f2' , borderRadius:100, padding:6, paddingLeft:25, paddingRight:25}} aria-label="Like">
            <FontAwesomeIcon icon={faArrowLeft} /> Kembali
          </Button>
        </Container>
            <Container direction='row' justifyContent='space-between' alignItems='center'>
                <Text size={'xl'} weight='bold'>{area.name}</Text>
                {/* <DropdownButton data={area} sections={getActions()} backdrop='blur' icon={faChevronDown} /> */}
                <Container style={{display:'flex', flexDirection:'row', gap:5}}>
                    <Button isIconOnly style={{backgroundColor:'#ef4727',color:'#fff'}} onPress={()=>setActiveModal('queues')}>
                        <FontAwesomeIcon icon={faListCheck} size='sm' color='#fff'/>
                    </Button>
                    <Button isIconOnly style={{color:'#fff'}} color='warning' onPress={()=>navigate(`/areas/${area.id}/update`)}>
                        <FontAwesomeIcon icon={faEdit} size='sm' color='#fff'/>
                    </Button>

                    {area && area.is_active ? <>
                        <Button isIconOnly style={{backgroundColor:'#19c362',color:'#fff'}} onPress={()=>setActiveModal('inactivate')}>
                        <FontAwesomeIcon icon={faToggleOn} size='lg' color='#fff'/>
                    </Button>
                    </> : <>
                    
                    <Button isIconOnly style={{backgroundColor:'#ef4727',color:'#fff'}} onPress={()=>setActiveModal('activate')}>
                        <FontAwesomeIcon icon={faToggleOff} size='lg' color='#fff'/>
                    </Button>
                    </>}
                    
                </Container>
                
            </Container>
            <Container gap={20}>
                <Container gap={5}>
                    <Text size={'small'} weight={'medium'}>Status</Text>
                    {!area.is_active ? <Chip color='danger'>Tidak Aktif</Chip> : null}
                    {area.is_active ? <Chip color='success'>Aktif / Sedang Berjalan</Chip> : null}
                </Container>
                <Container gap={5}>
                    {mapStatus.isLoaded ? <GoogleMap mapContainerStyle={{ height: 400, maxWidth: '100%', borderRadius: 10,  }}
                        zoom={15}
                        center={{ lat: Number(area.latitude), lng: Number(area.longitude) }} >
                        <MarkerF position={{ lat: Number(area.latitude), lng: Number(area.longitude) }} />
                        <Circle visible={true}
                            center={{ lat: Number(area.latitude), lng: Number(area.longitude) }}
                            radius={Number(area.radius) * 1000}
                            options={{fillColor: '#006cbe5e', strokeColor: '#006cbe'}} />
                    </GoogleMap> : null}
                </Container>
                <Container>
                    <Text size={'small'} weight={'medium'}>Koordinat (Lat, Long)</Text>
                    <Text>{area.latitude}, {area.longitude}</Text>
                </Container>
                <Container>
                    <Text size={'small'} weight={'medium'}>Radius</Text>
                    <Text>{area.radius} km</Text>
                </Container>
                <Divider />
            </Container>
            <AreaPriceList areaId={area.id} />
        </> : null}
        {activeModal === 'inactivate' && area ? <InactivateArea area={area} onClose={_onCloseModal} /> : null}
        {activeModal === 'activate' && area ? <ActivateArea area={area} onClose={_onCloseModal} /> : null}
		{activeModal === 'queues' && area ? <AreaQueueList area={area} onClose={_onCloseModal} /> : null}
    </Container>
};

export default AreaDetails;