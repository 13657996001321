import React, { useEffect, useState } from 'react';
import Container from '../../container';
import { Spinner,Button, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import Text from '../../text';
import { faCheck, faChevronDown, faEye, faQuestion, faToggleLargeOff, faToggleOn, faXmark } from '@fortawesome/pro-light-svg-icons';
import drivers from '../../../pages/portal/drivers';
import DropdownButton, { DropdownButtonSectionProps } from '../../buttons/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import DriverService from '../../../services/drivers';
import { IVehicleResourceShortProps } from '../../../props/drivers';
import VehicleCategoryService from '../../../services/vehicle/category';
import Banner from '../../banner';
// import { Button } from 'reactstrap';

type TypeDriverVehicleListProps = {
  children?: React.ReactNode;
  driverId: string
};

const DriverVehicleList: React.FC<TypeDriverVehicleListProps> = (props) => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState<boolean>(false)
  const [vehicles, setVehicles] = useState<IVehicleResourceShortProps[]>([])
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  
  const onUpdateVehicle = async (id:number) => {
    try {
      setSubmitting(true);
      const fd = new FormData();
      let m = await DriverService.vehicle.update_status_vehicle(id);
      // props.onClose(true);

      getVehicles()
    } catch(e) {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    getVehicles()
    return () => { }
  }, [])

  const getVehicles = async () => {
    try {
      const _vehicles = (await DriverService.vehicle.retrieve(props.driverId)).result
      setVehicles(_vehicles);
      setLoaded(true)
    } catch (error) {

    }
  }

  return loaded ? (
    <Table aria-label="Daftar Kendaraan" isStriped>
      <TableHeader>
        <TableColumn>Nama</TableColumn>
        <TableColumn>Status</TableColumn>
        <TableColumn width={40}> </TableColumn>
      </TableHeader>
      <TableBody isLoading={false} loadingContent={<Spinner />} emptyContent={<Text>Belum ada kendaraan terdaftar</Text>}>
        {
          vehicles.map(item=> (
            <TableRow key={item.id}>
              <TableCell>
                <Text size='base' weight='medium'>{item.vehicle_name} ({item.color})</Text>
              </TableCell>
              <TableCell>
                <FontAwesomeIcon color={item.is_verified ? '#007200' : '#B02E2C'} icon={item.is_verified ? faCheck : faXmark} />
              </TableCell>
              <TableCell key={'action'} style={{display:'flex', gap:5}}>
                {/* <DropdownButton icon={faChevronDown} sections={getItemDropdown(item)} backdrop='blur' data={undefined} /> */}
                <Button isIconOnly style={{backgroundColor:'#ef4727',color:'#fff'}} onPress={()=>navigate(`/drivers/${props.driverId}/vehicles/${item.id}`)}>
                      <FontAwesomeIcon icon={faEye} size='sm' color='#fff'/>
                  </Button>
                
                  <Button isIconOnly style={{backgroundColor:item.country_id?'green':'#000',color:'#fff'}} onPress={()=>onUpdateVehicle(item.id)}>
                      <FontAwesomeIcon icon={item.country_id?faToggleOn:faToggleLargeOff} size='sm' color='#fff'/>
                  </Button>

                {!item.is_verified ? <>
                  <Button style={{color:'#fff'}}  color='success' onPress={()=>navigate(`/drivers/${props.driverId}/vehicles/${item.id}/vertification`)}>
                  Verifikasi <FontAwesomeIcon icon={faQuestion} size='sm' color='#fff'/>
                  </Button>
                </> : null}
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  ) : <></>

  function getItemDropdown(item: IVehicleResourceShortProps): DropdownButtonSectionProps[] {
    const items = [
      {
        key: 'details', text: 'Lihat detail', onPress: () => {
          navigate(`/drivers/${props.driverId}/vehicles/${item.id}`);
        }
      },
    ];

    if (!item.is_verified) {
      items.unshift(
        {
          key: 'vertification', text: 'Verifikasi Kendaraan', onPress: () => {
            navigate(`/drivers/${props.driverId}/vehicles/${item.id}/vertification`);
          }
        });
    }

    return [
      {
        key: 'details',
        items: items
      },
    ];
  }
};

export default DriverVehicleList;