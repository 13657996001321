import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react';
import React, { useState } from 'react';
import Text from '../../text';
import Container from '../../container';
import { useNavigate } from 'react-router-dom';
import avatar from '../../../assets/avatar/avatar_1.png'
import { IDriverResourceShort } from '../../../props/drivers';
import GlobalConfig from '../../../config';
import DriverService from '../../../services/drivers';
import { Label } from 'reactstrap';

type UpdateDataModalAreaProps = {
  children?: React.ReactNode;
  onClose(refresh?: boolean): void;
  driver: IDriverResourceShort
};

const UpdateDataModalArea: React.FC<UpdateDataModalAreaProps> = (props) => {
  const { driver } = props
  const navigate = useNavigate();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [phone, setPhone] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFile2, setSelectedFile2] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };
  const handleFileChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile2(event.target.files[0]);
    }
  };

  const _onSubmit = async () => {
    const fd = new FormData;

    // console.log(selectedFile);
    // setSubmitting(false)
    // return false;

    if (selectedFile) {
        fd.append('profile_picture', selectedFile);
    }
    
    if (selectedFile2) {
        fd.append('id_proof', selectedFile2);
    }
    
    setSubmitting(true)
    try {
      await DriverService.updateFotoProfil(String(driver.id), fd)
      props.onClose(true)
    } catch (error) {
      setSubmitting(false)

    }
  }

  return (
    <Modal size='sm' isOpen={true} onOpenChange={(isOpen) => { if (!isOpen) { props.onClose() } }}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">Update Foto & KTP {driver.first_name} {driver.last_name||""}</ModalHeader>
        <ModalBody>
          <Container gap={20} alignItems='center' justifyContent='center'>
            <Text style={{textAlign:'center'}} size='xs'>Foto & KTP Saat Ini</Text>
            <Container direction='column' alignItems='center' gap={10}>
              <img
                src={GlobalConfig.assetHostname + '/storage/app/public/' + driver.profile_picture}
                alt="profile"
                style={{ height: 120, width: 120, display:'block', objectFit:'cover', borderRadius: 100 }} />
              <Container>
                {/* <Text size={'small'} weight={'medium'}>{driver.first_name} {driver.last_name||""}</Text> */}
                {/* <Text>{Number(driver.gender) === 1 ? 'Laki-laki' : 'Perempuan'}</Text> */}
              </Container>
            </Container>

            <Container style={{border:'1px solid #f2f2f2', borderRadius:5, gap:10, padding:10}}>
              <Label>Pilih Foto Profil</Label>
              <input type="file" onChange={handleFileChange} />
              {selectedFile && <Text size='xs'>{selectedFile.name}</Text>}
            </Container>
            <Container style={{border:'1px solid #f2f2f2', borderRadius:5, gap:10, padding:10}}>
              <Label>Pilih KTP</Label>
              <input type="file" onChange={handleFileChange2} />
              {selectedFile2 && <Text size='xs'>{selectedFile2.name}</Text>}
            </Container>
            
            
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button isDisabled={submitting} onPress={() => props.onClose()} style={{ width: '30%' }}>
            Batal
          </Button>
          <Button color="success" onPress={_onSubmit} isLoading={submitting} style={{ width: '70%' }}>
            Update Foto
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

export default UpdateDataModalArea;