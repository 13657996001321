import React, { ReactNode } from 'react';
import styles from '../admins/styles.module.scss';
interface TextProps {
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'small' | 'tiny' | 'large';
  weight?: 'thin' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold' | 'black';
  children?: ReactNode;
  style?: React.CSSProperties
  onClick?: React.MouseEventHandler<HTMLSpanElement>
}

const Text: React.FC<TextProps> = ({ size = 'sm', weight, children, style, onClick }) => {
  const textSizeClasses = `text-${size}`;
  const textWeightClasses = weight ? `font-${weight}` : '';

  return <p onClick={onClick} style={style} className={`${textSizeClasses} ${textWeightClasses} ${styles.fontFamily}`} >{children}</p>;
};

export default Text;
