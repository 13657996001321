import React, { useEffect, useState } from 'react';
import { Card,CardBody } from '@nextui-org/react';
import Text from '../../../components/text';
import styles from './GridThumbnailMenu.module.scss';
import AuthService from '../../../services/auth';
import Cookies from 'js-cookie';

type MenuItem = {
  icon: React.ReactNode;
  title: string;
  onClick: () => void;
};

type GridThumbnailMenuProps = {
  items: MenuItem[];
};

const handleLogout = () => {
  AuthService.logout();
};

const handleItemClick = (event:any, onClick:any) => {
  event.preventDefault();
  onClick(); // Call the onClick function passed from the item
};

const GridThumbnailMenu: React.FC<GridThumbnailMenuProps> = ({ items }) => {

  return (
    <div className={styles.gridContainer}>
      
      <div className={styles.grid}>
        {items.map((item, index) => (
          <div key={index} className={styles.gridItem}>
            <Card onClick={item.onClick} isHoverable isPressable style={{width:'100%'}}>
              <CardBody style={{ textAlign: 'center', padding: '20px', backgroundColor:'#ef4727'}}>
                {item.icon}
                <Text style={{ marginTop: '10px', color:'#fff',fontSize:11 }}>{item.title}</Text>
              </CardBody>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GridThumbnailMenu;
