import React from 'react';
import Text from '../text';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Input, Button, ButtonProps } from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../buttons/dropdown';
import Container from '../container';
import styles from './styles.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


type FooterProps = {
    children?: React.ReactNode;
    buttons?: FooterButtonProps[];
};

export interface FooterButtonProps extends ButtonProps {
    width?: string;
}

const Footer: React.FC<FooterProps> = (props) => {
    return <Container className={styles.container}  style={{paddingLeft: 20,zIndex:99999}}>
        {props.children ? props.children : null}
        {!props.children && props.buttons ? <Container direction='row' gap={10}>
            {props.buttons.map((button) => {
                return <Button {...button} style={{width: button.width}}>{button.children}</Button>
            })}
        </Container> : null}
    </Container>
};

export default Footer;