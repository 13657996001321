// src/components/RitaseChart.js

import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const RitaseChart = ({ data }) => {
  const labels = data.map(entry => entry.date);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Total Ritase',
        data: data.map(entry => entry.jumlah_ritase),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: false,
      },
      {
        label: 'Ritase Batal',
        data: data.map(entry => entry.ritase_dibatalkan),
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        fill: false,
      },
      {
        label: 'Ritase On Going',
        data: data.map(entry => entry.ritase_diproses),
        borderColor: 'rgba(255, 206, 86, 1)',
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        fill: false,
      },
      {
        label: 'Ritase Selesai',
        data: data.map(entry => entry.ritase_selesai),
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        fill: false,
      }
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Grafik Jumlah Ritase 30 Hari Terakhir',
      },
    },
    elements: {
      line: {
        tension: 0.4, // This controls the curvature of the line. The higher the value, the more curved the line.
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default RitaseChart;
