import React from 'react';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss';
import ZoneForm from '../../../components/zones/form';

type CreateZonePageProps = {
  children?: React.ReactNode;
};

const CreateZonePage: React.FC<CreateZonePageProps> = (props) => {
  return <Container className={styles.container} gap={20}>
      <Text size={'xl'} weight='bold'>Tambah Zona</Text>
      <ZoneForm />
  </Container>;
};

export default CreateZonePage;