import React from 'react';
import styles from "./styles.module.scss";
import Container from '../../../components/container';
import { Button, Card, CardBody, Input, Image} from '@nextui-org/react';
import AuthService from '../../../services/auth';
import Text from '../../../components/text';


type LoginPageProps = {
  children?: React.ReactNode;
};

type LoginData = {
  username: string;
  password: string;
}

const LoginPage: React.FC<LoginPageProps> = (props) => {
  const [data, setData] = React.useState<LoginData>({
    username: "",
    password: ""
  });
  const [errorData, setErrorData] = React.useState<any>({});
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const _onSubmit = async () => {
    setSubmitting(true);
    try {
      await AuthService.login(data.username, data.password);
      localStorage.setItem('username', data.username);
      window.location.href = "/";
    } catch (e) {
      console.log(e);
      setSubmitting(false);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftColumn}>
        <Container direction={'column'} gap={25} alignItems='center' justifyContent='center'>
        <Image style={{ width: 200, height: 200, marginBottom:-50, marginTop:-100 }} src={require('../../../assets/logo/4.png')} /> 
          <Card className={styles.card}>
            <CardBody className='gap-5'>
              <Text size='large' style={{textAlign:'center'}}>Login</Text>
              <Input
                isRequired={true}
                isReadOnly={submitting}
                label="Username"
                placeholder=" "
                labelPlacement='inside'
                value={data.username}
                onValueChange={(value) => {
                  data.username = value || "";
                  setData({ ...data });
                }}
              />
              <Input
                isRequired={true}
                isReadOnly={submitting}
                type="password"
                label="Password"
                placeholder=" "
                labelPlacement="inside"
                value={data.password}
                onValueChange={(value) => {
                  data.password = value || "";
                  setData({ ...data });
                }}
              />
              <Button size='sm' color='warning' style={{borderRadius:100, color : '#fff', fontWeight:'bold', backgroundColor:'', padding:20}} className={styles.bgPrimary} isLoading={submitting} onClick={_onSubmit}>Login</Button>
            </CardBody>
          </Card>
        </Container>
      </div>
      <div className={styles.rightColumn}>
        {/* The right column will display the background image */}
      </div>
    </div>
  )
};

export default LoginPage;
