import React, { useEffect, useState } from 'react';
import Container from '../container';
import { Button, Input } from '@nextui-org/react';
import { useParams } from 'react-router-dom';
import Text from '../text';
import { useNavigate } from "react-router-dom";
import AdminService from '../../services/admins';
import { style } from '@mui/system';
import styles from '../../assets/css/styles.module.scss';
import { FormControl, formControlClasses } from '@mui/base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';

type InputAdminDataType = {
  name?: string
  username?: string
  password?: string
  repeatPassword?: string  // Tambahkan repeatPassword ke dalam tipe data
}

type TypeAdminFormProps = {
  children?: React.ReactNode;
};

const AdminForm: React.FC<TypeAdminFormProps> = (props) => {
  const navigate = useNavigate();
  const { adminId } = useParams();

  const [data, setData] = useState<InputAdminDataType>({})
  const [submitting, setSubmitting] = useState<boolean>(false)

  useEffect(() => {
    getAdmin()
    return () => { }
  }, [])

  const getAdmin = async () => {
    if (adminId)
      try {
        const admin = await AdminService.get(adminId)
        setData({ name: admin.name, username: admin.username })
      } catch (error) {

      }
  }

  const onSave = async () => {
    if (data.password !== data.repeatPassword) {
      return;
    }

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => value && value.trim() !== '' && formData.append(key, value));
    setSubmitting(true)
    try {
      !adminId ? await AdminService.create(formData) : await AdminService.update(formData, adminId)
      navigate('/admins')
    } catch (error) {
      setSubmitting(false)

    }
  }

  return (
    <Container gap={15} style={{
        backgroundColor: 'white',
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
        // alignItems: 'center',
        // justifyContent: 'center',
        // height: '80vh',
      }}>

       <Container style={{display:'flex', justifyContent:'start',alignItems:'start'}}>
        <Button onClick={() => {navigate(-1)}} style={{backgroundColor:'#f2f2f2' , borderRadius:100, padding:6, paddingLeft:25, paddingRight:25}} aria-label="Like">
            <FontAwesomeIcon icon={faArrowLeft} /> Kembali
          </Button>
        </Container>

      <Text size='xl' weight='bold'>{adminId ? "Update" : "Tambah"} Root Access</Text>

      <label style={{color:'#444', fontSize:10, lineHeight:0, marginTop:15}}>Nama Administrator</label>
      <Input
        value={data.name}
        onChange={e => setData({ ...data, name: e.target.value })}
        size='md'
        placeholder="Masukkan nama lengkap"
        labelPlacement="outside"
      />
      <label style={{color:'#444', fontSize:10, lineHeight:0, marginTop:7}}>Username</label>
      <Input
        value={data.username}
        onChange={e => setData({ ...data, username: e.target.value })}
        size='md'
        placeholder="Username"
        labelPlacement="outside"
      />
      {!adminId && (
        <>
        <label style={{color:'#444', fontSize:10, lineHeight:0, marginTop:7}}>Password</label>
          <Input
            value={data.password}
            onChange={e => setData({ ...data, password: e.target.value })}
            size='md'
            placeholder="Password"
            type='password'
            labelPlacement="outside"
          />
          <label style={{color:'#444', fontSize:10, lineHeight:0, marginTop:7}}>Re-type Password</label>
          <Input
            value={data.repeatPassword}
            onChange={e => setData({ ...data, repeatPassword: e.target.value })}
            size='md'
            placeholder="Ulangi Password"
            type='password'
            labelPlacement="inside"
          />
        </>
      )}
      <Container
        gap={10}
        direction='row'
        style={{ bottom: 0, width: '100%', zIndex:9999, padding: 20, background: 'white', position: "absolute", left: 0 }}>
        <Button disabled={submitting} size='md' color='primary' className={styles.bgPrimer} style={{ flex: 1 }} onClick={onSave}>Simpan Perubahan</Button>
        <Button disabled={submitting} size='md' onClick={() => navigate('/admins')}>Batal</Button>
      </Container>
    </Container>
  );
};

export default AdminForm;
