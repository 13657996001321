import Cookies from "js-cookie";
import Request from "../general/request";
import { IUserResourceShort } from "../../props/admin/customer";

const AuthService = {
  login: async (username: string, password: string) => {
    try {
      const data = new FormData();
      data.append('username', username);
      data.append('password', password);

      const response = await Request.post<IUserResourceShort>('/api/admin/login', data, { disableToken: true });
      Cookies.set('token', response.result.token);
      Cookies.set('name', response.result.name);

      return response;
    } catch (error) {
      throw error
    }
  },
  logout: () => {
    Cookies.remove('token');
    Cookies.remove('name');
    localStorage.removeItem('username');
    // You can also add additional logout logic here, such as redirecting the user to the login page.

    window.location.reload();
  }
}

export default AuthService;