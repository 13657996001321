import React, { useEffect } from 'react';
import Text from '../../text';
import { faChevronDown, faEdit, faEye, faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Spinner, Input, Button } from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../../buttons/dropdown';
import Container from '../../container';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IZoneResourceShortProps } from '../../../props/zones/zone';
import ZoneService from '../../../services/zones/zone';


type TypeListZoneProps = {
	children?: React.ReactNode;
};

const ListZone: React.FC<TypeListZoneProps> = (props) => {
	const navigate = useNavigate();
	
	const [zones, setZones] = React.useState<IZoneResourceShortProps[]>([]);
	const [loaded, setLoaded] = React.useState<boolean>(false);
	const [search, setSearch] = React.useState<string>("");  
	const [activeZone, setActiveZone] = React.useState<IZoneResourceShortProps | undefined>();
	const [activeModal, setActiveModal] = React.useState<string>("");

	const actions: DropdownButtonSectionProps[] = [
		{
			key: 'details',
			showDivider: true,
			items: [
				{ key: 'view', text: 'Lihat detail', onPress: (zone: IZoneResourceShortProps) => {navigate(`/zones/${zone.id}/details`);} },
				{ key: 'update', text: 'Ubah detail', onPress: (zone: IZoneResourceShortProps) => {navigate(`/zones/${zone.id}/update`);} }
			]
		},
		{
			key: 'deletion',
			items: [
				{ key: 'queue', text: 'Non-aktifkan zona', variant: 'danger', onPress: (zone: IZoneResourceShortProps) => {
					setActiveZone(zone);
					setActiveModal("inactivate");
				} }
			]
		}
	];

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		const _zones = (await ZoneService.retrieve()).result;
		setZones(_zones);
		setLoaded(true);
	}

	const _onCloseModal = (refresh?: boolean) => {
		setActiveModal("");
		setActiveZone(undefined);

		if (refresh) {
			setLoaded(false);
			init();
		}
	}

	return <Container gap={20}>
		<Container direction='row' gap={10} style={{ zIndex: 1,width:'100%', justifyContent:'space-between', alignItems:'end', display:'flex', flexDirection:'row', flex:1}}>
			<Input
				placeholder="Cari zona ..."
				startContent={
					<FontAwesomeIcon icon={faSearch} />
				}
				color='warning'
				style={{ zIndex: 1 }}
				value={search}
				onValueChange={(value) => {
					setSearch(value);
				}}
			/>
			<NavLink to={"/zones/create"}>
				<Button color='danger' style={{backgroundColor:'#ef4727'}}>
					<FontAwesomeIcon icon={faPlus} /> Tambah
				</Button>
			</NavLink>
		</Container>
		<Table aria-label="Daftar zona" isStriped>
			<TableHeader>
				<TableColumn width={40}>No</TableColumn>
				<TableColumn>NAMA</TableColumn>
				<TableColumn width={40}> </TableColumn>
			</TableHeader>
			<TableBody isLoading={!loaded} loadingContent={<Spinner />} emptyContent={<Text>Belum ada zona terdaftar</Text>}>
				{zones.filter((zone) => zone.name.toLowerCase().indexOf(search.toLowerCase()) > -1).map((zone,index) => {
					return <TableRow key={zone.id}>
						<TableCell>
							<Text size='base' weight='medium'>{index+1}</Text>
						</TableCell>
						<TableCell>
							<Text size='base' weight='medium'>{zone.name}</Text>
						</TableCell>
						{/* <TableCell key={'action'}>
							<DropdownButton icon={faChevronDown} sections={actions} backdrop='blur' data={zone} />
						</TableCell> */}
						<TableCell style={{display:'flex', flexDirection:'row', gap:10}}>
						<Button isIconOnly color='primary' size='sm' onClick={()=>navigate(`/zones/${zone.id}/details`)}>
							<FontAwesomeIcon icon={faEye} />
						</Button>
						<Button isIconOnly color='warning' size='sm' onClick={()=>navigate(`/zones/${zone.id}/update`)}>
							<FontAwesomeIcon style={{color:'#fff'}} icon={faEdit} />
						</Button>
						</TableCell>
					</TableRow>;
				})}
			</TableBody>
		</Table>
		{/*activeModal === 'inactivate' && activeArea ? <InactivateArea area={activeArea} onClose={_onCloseModal} /> : null}
		{activeModal === 'queues' && activeArea ? <AreaQueueList area={activeArea} onClose={_onCloseModal} /> : null*/}
	</Container>;
};

export default ListZone;