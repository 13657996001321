import React, { useEffect } from 'react';
import Text from '../../../text';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Spinner, Divider } from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../../../buttons/dropdown';
import Container from '../../../container';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import NumberService from '../../../../services/general/number';
import { IZoneResourceShortProps } from '../../../../props/zones/zone';
import { IZonePriceResourceShortProps } from '../../../../props/zones/price';
import ZonePriceService from '../../../../services/zones/price';
import ZoneService from '../../../../services/zones/zone';
import DeleteZonePrice from '../modals/delete';


type ZonePriceDetailsProps = {
    zoneId: number;
    priceId: number;
};

const ZonePriceDetails: React.FC<ZonePriceDetailsProps> = (props) => {
    const navigate = useNavigate();

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [zone, setZone] = React.useState<IZoneResourceShortProps | undefined>();
    const [price, setPrice] = React.useState<IZonePriceResourceShortProps | undefined>();
	const [activeModal, setActiveModal] = React.useState<string>("");

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const _zone = (await ZoneService.read(props.zoneId)).result;
            setZone(_zone);
            
            const _price = (await ZonePriceService.read(props.zoneId, props.priceId)).result;
            setPrice(_price);

            setLoaded(true);
        } catch(e) {
            navigate(`/zones/${props.zoneId}`)
        }
    }

	const actions: DropdownButtonSectionProps[] = [
		{
			key: 'details',
			showDivider: true,
			items: [
				{ key: 'update', text: 'Ubah detail', onPress: () => {navigate(`/zones/${props.zoneId}/prices/${props.priceId}/update`);} }
			]
		},
		{
			key: 'deletion',
			items: [
				{ key: 'queue', text: 'Hapus harga', variant: 'danger', onPress: () => {setActiveModal('delete');} }
			]
		}
	];

	const _onCloseModal = (refresh?: boolean) => {
		setActiveModal("");

		if (refresh) {
			setLoaded(false);
			init();
		}
	}

    return <Container className={styles.container} gap={20} style={{
            backgroundColor: 'white',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 0,
            // alignItems: 'center',
            // justifyContent: 'center',
            // height: '80vh',
        }}>
        {!loaded ? <Spinner label='Memuat detail ...' /> : null}
        {loaded && zone && price ? <>
            <Container direction='row' justifyContent='space-between' alignItems='center'>
                <Text size={'xl'} weight='bold'>Detail Harga</Text>
                <DropdownButton data={zone} sections={actions} backdrop='blur' icon={faChevronDown} />
            </Container>
            <Container gap={20}>
                <Container>
                    <Text size={'small'} weight={'medium'}>Zona</Text>
                    <Text>{zone.name}</Text>
                </Container>
                <Container>
                    <Text size={'small'} weight={'medium'}>Jarak</Text>
                    {price.max_km > 0 ? <Text>{Number(price.min_km)}-{Number(price.max_km)} km</Text> : null}
                    {price.max_km <= 0 ? <Text>{`>`} {Number(price.min_km)} km</Text> : null}
                </Container>
                <Divider />
                <Container>
                    <Text size={'small'} weight={'medium'}>Harga dasar</Text>
                    <Text>Rp. {NumberService.getNumberWithSeparator(Number(price.base_price))}</Text>
                </Container>
                <Container>
                    <Text size={'small'} weight={'medium'}>Harga minimum</Text>
                    <Text>Rp. {NumberService.getNumberWithSeparator(Number(price.min_price))}</Text>
                </Container>
                <Container>
                    <Text size={'small'} weight={'medium'}>Harga per km</Text>
                    <Text>Rp. {NumberService.getNumberWithSeparator(Number(price.price_per_km))} / km</Text>
                </Container>
                <Container>
                    <Text size={'small'} weight={'medium'}>Surcharge</Text>
                    <Text>Rp. {NumberService.getNumberWithSeparator(Number(price.surcharge))}</Text>
                </Container>
                <Container>
                    <Text size={'small'} weight={'medium'}>Persentase potongan</Text>
                    <Text>{NumberService.getNumberWithSeparator(Number(price.percentage_fee))}%</Text>
                </Container>
                <Divider />
                <Container>
                    <Text size={'small'} weight={'medium'}>Biaya parkir</Text>
                    <Text>Rp. {NumberService.getNumberWithSeparator(Number(price.parking_fee))}</Text>
                </Container>
                <Container>
                    <Text size={'small'} weight={'medium'}>Biaya parkir harian</Text>
                    <Text>Rp. {NumberService.getNumberWithSeparator(Number(price.daily_parking_fee))} / hari</Text>
                </Container>
            </Container>
        </> : null}
		{activeModal === 'delete' && zone && price ? <DeleteZonePrice zone={zone} price={price} onClose={_onCloseModal} /> : null}
    </Container>
};

export default ZonePriceDetails;