import React from 'react';
import AreaForm from '../../../components/areas/form';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss';

type CreateAreaPageProps = {
  children?: React.ReactNode;
};

const CreateAreaPage: React.FC<CreateAreaPageProps> = (props) => {
  return <Container className={styles.container} gap={20}>
      <Text size={'xl'} weight='bold'>Tambah Area</Text>
      <AreaForm />
  </Container>;
};

export default CreateAreaPage;