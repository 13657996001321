import React, { useEffect, useState } from 'react';
import Text from '../../../components/text';
import styles from './styles.module.scss';
import Container from './../../../components/container';
import ListOrder from '../../../components/orders/list';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select, SelectItem, Selection, Spinner, Card, CardBody, CardFooter, CardHeader, Divider } from '@nextui-org/react';
import TripService from '../../../services/trips';
import { ITripResourceShortProps, ITripHistoriesResourceShortProps } from '../../../props/trips';
import NumberService from '../../../services/general/number';
import { IDriverWalletHistoryResourceShortProps } from '../../../props/drivers/walletHistory';
import { IDriverWalletHistoryNewResourceShortProps } from '../../../props/drivers/walletHistoryNew';

import DriverService from '../../../services/drivers';
import Footer from '../../../components/footerpage';

import RitaseChart from '../../../components/chart/RitaseChart';
import RitaseChart2 from '../../../components/chart/RitaseChart_2';
// import { Button } from 'reactstrap';
import { Button } from '@mui/material'; // Assuming you are using MUI, otherwise use your preferred button component


type AnalyticDashboardPageProps = {
  children?: React.ReactNode;
};

interface RitaseData {
  date: string;
  total_absensi: number;
  total_cash: number;
  total_ewallet: number;
  total_qrcode: number;
  total_topup: number;
}

interface RitaseData2 {
  date: string;
  jumlah_ritase: number;
  ritase_dibatalkan: number;
  ritase_diproses: number;
  ritase_selesai: number;
}


const AnalyticDashboardPage: React.FC<AnalyticDashboardPageProps> = (props) => {
  const [duration, setDuration] = useState("today");
  const [trips, setTrips] = React.useState<ITripResourceShortProps[]>([]);
  const [driverWalletHistories, setDriverWalletHistories] = React.useState<IDriverWalletHistoryResourceShortProps[]>([]);
  const [driverAbsensiHistories, setDriverAbsensiHistories] = React.useState<IDriverWalletHistoryResourceShortProps[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(true);

  const [driverWalletHistoriesNew, setDriverWalletHistoriesNew] = React.useState<IDriverWalletHistoryNewResourceShortProps>({total_topup:0, total_ewallet:0, total_cash:0, total_qrcode:0, total_absensi:0});
  const [tripsHistories, setTripsHistories] = React.useState<ITripHistoriesResourceShortProps>({jumlah_ritase:0, ritase_selesai:0, ritase_diproses:0, ritase_dibatalkan:0, incomes:0, applicationFee:0, adminFee:0});


  const [ritaseData, setRitaseData] = useState<RitaseData[]>([]);
  const [ritaseData2, setRitaseData2] = useState<RitaseData2[]>([]);

  const [selectedDuration, setSelectedDuration] = useState(null);


  
  useEffect(() => {
    _onSearch("today");
    _runChartData();
    _onDurationChange2(durations[0].value);
    
    // const fetchData = async () => {
    //   // Contoh data
    //   const exampleData = [
    //     { date: '2024-06-01', total: 10, completed: 8, inProgress: 1, canceled: 1 },
    //     { date: '2024-06-02', total: 12, completed: 10, inProgress: 1, canceled: 1 },
    //     { date: '2024-06-03', total: 8, completed: 7, inProgress: 0, canceled: 1 }
    //   ];

    //   // setRitaseData(exampleData); 
    //   // Gantilah exampleData dengan data yang Anda ambil dari API
    // };

    // fetchData();
  }, []);
  

  // const _onDurationChange = (value: any) => {
  //   setDuration(value);
  //   _onSearch(value.currentKey);
  // }

  const _onDurationChange2 = (value: any) => {
    setDuration(value);
    console.log(value.value);
    _onSearch(value.value);
    setSelectedDuration(value.value);
  }


  const jakartaTimeZone = 'Asia/Jakarta';

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const _runChartData = async () => {
  
  const jakartaTimeZone = 'Asia/Jakarta';
  const currentDateTime = new Date(new Date().toLocaleString('en-US', { timeZone: jakartaTimeZone }));
  function formatDate(date:Date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    let startDate = new Date(currentDateTime);
    startDate.setDate(currentDateTime.getDate() - 10);

    let endDate = new Date(currentDateTime);
    endDate.setHours(23, 59, 59, 0);
    endDate = new Date(formatDate(endDate));
    endDate = new Date(currentDateTime);
    endDate.setHours(23, 59, 59, 0);
    endDate.setDate(currentDateTime.getDate());
    endDate = new Date(formatDate(endDate));

    const qs = [
      `start_time=${formatDate(startDate)}`,
      `end_time=${formatDate(endDate)}`
    ];

    const _driverWalletHistoriesNew = (await DriverService.wallet.history.retrieveNewDays(qs.join("&"))).result;
    const _tripsNew = (await TripService.retrieveNewDays(qs.join("&"))).result;

    
    console.log("hasilnya : ", _driverWalletHistoriesNew);
    setRitaseData(_driverWalletHistoriesNew);
    setRitaseData2(_tripsNew);

  }

  const _onSearch = async (_duration: string) => {
    setLoaded(false);

    const currentDateTime = new Date(new Date().toLocaleString('en-US', { timeZone: jakartaTimeZone }));
    let startDate = new Date(currentDateTime);
    startDate.setHours(0, 0, 1, 0);
    startDate = new Date(formatDate(startDate));

    let endDate = new Date(currentDateTime);
    endDate.setHours(23, 59, 59, 0);
    endDate = new Date(formatDate(endDate));

    console.log(startDate)
    console.log(endDate)

    if (_duration === 'yesterday') {
      startDate = new Date(currentDateTime);
      startDate.setHours(0, 0, 1, 0);
      startDate.setDate(currentDateTime.getDate() - 1);
      startDate = new Date(formatDate(startDate));

      endDate = new Date(currentDateTime);
      endDate.setHours(23, 59, 59, 0);
      endDate.setDate(currentDateTime.getDate() - 1);
      endDate = new Date(formatDate(endDate));
    } else if (_duration === 'thisWeek') {
      startDate = new Date(currentDateTime);
      startDate.setHours(0, 0, 1, 0);
      startDate.setDate(currentDateTime.getDate() - currentDateTime.getDay());
      startDate = new Date(formatDate(startDate));

      endDate = new Date(currentDateTime);
      endDate.setHours(23, 59, 59, 0);
      endDate.setDate(currentDateTime.getDate() + (6 - currentDateTime.getDay()));
      endDate = new Date(formatDate(endDate));
    } else if (_duration === 'lastWeek') {
      startDate = new Date(currentDateTime);
      startDate.setHours(0, 0, 1, 0);
      startDate.setDate(currentDateTime.getDate() - currentDateTime.getDay() - 7);
      startDate = new Date(formatDate(startDate));

      endDate = new Date(currentDateTime);
      endDate.setHours(23, 59, 59, 0);
      endDate.setDate(currentDateTime.getDate() - currentDateTime.getDay() - 1);
      endDate = new Date(formatDate(endDate));
    } else if (_duration === 'thisMonth') {
      startDate = new Date(currentDateTime);
      startDate.setHours(0, 0, 1, 0);
      startDate.setDate(1);
      startDate = new Date(formatDate(startDate));

      endDate = new Date(currentDateTime);
      endDate.setHours(23, 59, 59, 0);
      endDate.setMonth(currentDateTime.getMonth() + 1, 0);
      endDate = new Date(formatDate(endDate));
    } else if (_duration === 'lastMonth') {
      startDate = new Date(currentDateTime);
      startDate.setHours(0, 0, 1, 0);
      startDate.setDate(1);
      startDate.setMonth(currentDateTime.getMonth() - 1);
      startDate = new Date(formatDate(startDate));

      endDate = new Date(currentDateTime);
      endDate.setHours(23, 59, 59, 0);
      endDate.setDate(0);
      endDate = new Date(formatDate(endDate));
    }

    const qs = [
      `start_time=${formatDate(startDate)}`,
      `end_time=${formatDate(endDate)}`
    ];

    // const _trips = (await TripService.retrieve(qs.join("&"))).result;
    // const _driverWalletHistories = (await DriverService.wallet.history.retrieve(qs.join("&"))).result;
    // const _driverWalletAbsensi = (await DriverService.wallet.history.absensi(qs.join("&"))).result;

    const _driverWalletHistoriesNew = (await DriverService.wallet.history.retrieveNew(qs.join("&"))).result;
    const _tripsNew = (await TripService.retrieveNew(qs.join("&"))).result;

    // setTrips(_trips);
    // setDriverWalletHistories(_driverWalletHistories);
    // setDriverAbsensiHistories(_driverWalletAbsensi);

    setDriverWalletHistoriesNew(_driverWalletHistoriesNew);
    setTripsHistories(_tripsNew);
    setLoaded(true);
  }

  const renderTripSummaryCardSecond = () => {
    return <Card  style={{borderRadius:0}}>
      <CardBody>
      <Container gap={5}>
            <Text weight={'semibold'}>Biaya Trips</Text>
            {/* <Text size={'tiny'}>Total biaya hanya dihitung dari ritase/pesanan yang sudah selesai.</Text> */}
          </Container>
        <Divider style={{marginTop:17, marginBottom:17}}/>
      <Container gap={20}>
        <Container direction='row' justifyContent='space-between'>
          <Container grow={1}>
            <Text size={'small'}>Total Biaya Perjalanan</Text>
          </Container>
          <Container alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
            <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(tripsHistories.incomes || 0)}</Text>
          </Container>
        </Container>
        <Container direction='row' justifyContent='space-between'>
          <Container grow={1}>
            <Text size={'small'}>Total Potongan Perjalanan</Text>
          </Container>
          <Container alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
            <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(tripsHistories.applicationFee || 0)}</Text>
          </Container>
        </Container>
        <Container direction='row' justifyContent='space-between'>
          <Container grow={1}>
            <Text size={'small'}>Total Biaya Pemeliharaan Aplikasi</Text>
          </Container>
          <Container alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
            <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(tripsHistories.adminFee || 0)}</Text>
          </Container>
        </Container>
        <Container direction='row' justifyContent='space-between'>
          <Container grow={1}>
            <Text size={'small'}>Total Absensi</Text>
          </Container>
          <Container alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
            <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(driverWalletHistoriesNew.total_absensi || 0)}</Text>
          </Container>
        </Container>
      </Container>
      </CardBody>
      </Card>
  }
  const renderTripSummaryCard = () => {
    // const completedTrips = trips.filter((trip) => trip.status === 'completed');
    // const cancelledTrips = trips.filter((trip) => trip.status === 'cancelled');
    // const inProgressTrips = trips.filter((trip) => trip.status === 'picking_up' || trip.status === 'dropping_off');
    // const totalIncomes = TripService.analytics.totalIncomes(completedTrips);
    // const totalAmount = DriverService.wallet.history.analytics.getTotalAmount(driverAbsensiHistories);

    return <Card  style={{borderRadius:0}}>
      <CardBody>
        <Container gap={20}>
          <Container gap={5}>
            <Text weight={'semibold'}>Ritase</Text>
            <Text size={'tiny'}>Total biaya hanya dihitung dari ritase/pesanan yang sudah selesai.</Text>
          </Container>
          <Divider />
          <Container gap={20}>
            <Container direction='row' justifyContent='space-between'>
              <Container grow={1}>
                <Text size={'small'}>Jumlah Semua Ritase</Text>
              </Container>
              <Container alignItems={'flex-end'} style={{ minWidth: 75, maxWidth: 75 }}>
                <Text size={'small'}>{tripsHistories.jumlah_ritase}</Text>
              </Container>
            </Container>
            <Container direction='row' justifyContent='space-between'>
              <Container grow={1}>
                <Text size={'small'}>Jumlah Ritase Selesai</Text>
              </Container>
              <Container alignItems={'flex-end'} style={{ minWidth: 75, maxWidth: 75 }}>
                <Text size={'small'}>{tripsHistories.ritase_selesai}</Text>
              </Container>
            </Container>
            <Container direction='row' justifyContent='space-between'>
              <Container grow={1}>
                <Text size={'small'}>Jumlah Ritase Dalam Perjalanan</Text>
              </Container>
              <Container alignItems={'flex-end'} style={{ minWidth: 75, maxWidth: 75 }}>
                <Text size={'small'}>{tripsHistories.ritase_diproses}</Text>
              </Container>
            </Container>
            <Container direction='row' justifyContent='space-between'>
              <Container grow={1}>
                <Text size={'small'}>Jumlah Ritase Dibatalkan</Text>
              </Container>
              <Container alignItems={'flex-end'} style={{ minWidth: 75, maxWidth: 75 }}>
                <Text size={'small'}>{tripsHistories.ritase_dibatalkan}</Text>
              </Container>
            </Container>
          </Container>
        </Container>
      </CardBody>
    </Card>
  }
  
  
  const renderTripChart = () => {
    // const completedTrips = trips.filter((trip) => trip.status === 'completed');
    // const cancelledTrips = trips.filter((trip) => trip.status === 'cancelled');
    // const inProgressTrips = trips.filter((trip) => trip.status === 'picking_up' || trip.status === 'dropping_off');
    // const totalIncomes = TripService.analytics.totalIncomes(completedTrips);
    // const totalAmount = DriverService.wallet.history.analytics.getTotalAmount(driverAbsensiHistories);

    return <Card style={{borderRadius:0}}>
      <CardBody>
        <Container gap={20}>
          <Container gap={5}>
            <Text weight={'semibold'}>Chart</Text>
          </Container>
          <Divider />
          <Container style={{display:'flex', flexDirection:'row', flex:1, flexGrow:1}}>
            <Container style={{width:'100%'}} gap={20}>
            {ritaseData2.length > 0 ? (
              <RitaseChart2 data={ritaseData2} />
            ) : (
              <p>Loading data...</p>
            )}
            </Container>
            <Container style={{width:'100%'}} gap={20}>
            {ritaseData.length > 0 ? (
              <RitaseChart data={ritaseData} />
            ) : (
              <p>Loading data...</p>
            )}
            </Container>

          </Container>
        </Container>
      </CardBody>
    </Card>
  }

  const renderDriverTopUpSummaryCard = () => {
    // const totalAmount = DriverService.wallet.history.analytics.getTotalAmount(driverWalletHistories);

    return <Card  style={{borderRadius:0}}>
    <CardBody>
      <Container gap={20}>
        <Container gap={5}>
          <Text weight={'semibold'}>Keuangan</Text>
        </Container>
        <Divider />
        <Container gap={20}>
          <Container direction='row' justifyContent='space-between'>
            <Container grow={1}>
              <Text size={'small'}>Total Top-up (Semua Metode Pembayaran)</Text>
            </Container>
            <Container alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
              <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(driverWalletHistoriesNew.total_topup || 0)}</Text>
            </Container>
          </Container>
          <Container direction='row' justifyContent='space-between'>
            <Container grow={1}>
              <Text size={'small'}>Total Pembayaran Cash</Text>
            </Container>
            <Container alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
              <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(driverWalletHistoriesNew.total_cash || 0)}</Text>
            </Container>
          </Container>
          <Container direction='row' justifyContent='space-between'>
            <Container grow={1}>
              <Text size={'small'}>Jumlah Pembayaran QRIS</Text>
            </Container>
            <Container  alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
              <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(driverWalletHistoriesNew.total_qrcode || 0)}</Text>
            </Container>
          </Container>
          <Container direction='row' justifyContent='space-between'>
            <Container grow={1}>
              <Text size={'small'}>Jumlah Pembayaran E-Wallet</Text>
            </Container>
            <Container  alignItems={'flex-end'} style={{ minWidth: 125, maxWidth: 125 }}>
              <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(driverWalletHistoriesNew.total_ewallet || 0)}</Text>
            </Container>
          </Container>
        </Container>
      </Container>
    </CardBody>
    </Card>
  }

  const durations = [
    { key: "today", value: "today", label: "Hari ini" },
    { key: "yesterday", value: "yesterday", label: "Kemarin" },
    { key: "thisWeek", value: "thisWeek", label: "Minggu ini" },
    { key: "lastWeek", value: "lastWeek", label: "Minggu lalu" },
    { key: "thisMonth", value: "thisMonth", label: "Bulan ini" },
    { key: "lastMonth", value: "lastMonth", label: "Bulan lalu" },
  ];

  const handleDurationChange = (value:any) => {
    // setSelectedDuration(value);
    setDuration(value);
    // Add any additional logic you need to handle the selection change
  };


  return <Container gap={20} style={{ padding: 20, marginBottom:100 }}>

    {renderTripChart()}


    {/* <Text weight={'semibold'}>Rekam Jejak</Text>
    <Select fullWidth={true} label="Pilih durasi" selectionMode={'single'} className="max-w-xs" selectedKeys={duration} onSelectionChange={_onDurationChange}>
      <SelectItem key={"today"} value={"today"}>Hari ini</SelectItem>
      <SelectItem key={"yesterday"} value={"yesterday"}>Kemarin</SelectItem>
      <SelectItem key={"thisWeek"} value={"thisWeek"}>Minggu ini</SelectItem>
      <SelectItem key={"lastWeek"} value={"lastWeek"}>Minggu lalu</SelectItem>
      <SelectItem key={"thisMonth"} value={"thisMonth"}>Bulan ini</SelectItem>
      <SelectItem key={"lastMonth"} value={"lastMonth"}>Bulan lalu</SelectItem>
    </Select> */}

    <div className="grid grid-cols-3 lg:grid-cols-6 gap-2 w-full">
      {durations.map((duration) => (
        <Button
        key={duration.key}
        onClick={() => _onDurationChange2(duration)}
        style={{
          width: '100%',
          backgroundColor: selectedDuration === duration.value ? '#ef4727' : '#ffffff',
          borderColor: '#ef4727',
          borderWidth: '1px',
          fontSize:'11px',
          padding:'4px',
          color: selectedDuration === duration.value ? '#ffffff' : '#000000',
        }}
      >
          {duration.label}
        </Button>
      ))}
    </div>
    {!loaded ? <Spinner size='md' color='danger' label={'Processing ...'} /> : null}
    {loaded ? <Container gap={20}>
      {renderTripSummaryCard()}
      {renderTripSummaryCardSecond()}
      {renderDriverTopUpSummaryCard()}
    </Container> : null}

    
  </Container>
};

export default AnalyticDashboardPage;
