import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react';
import React from 'react';
import Text from '../../text';
import Container from '../../container';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import GlobalConfig from '../../../config';
import { useNavigate } from 'react-router-dom';
import { IZoneResourceShortProps } from '../../../props/zones/zone';
import ZoneService from '../../../services/zones/zone';

type ActivateZoneProps = {
  children?: React.ReactNode;
  zone: IZoneResourceShortProps;
  onClose(refresh?: boolean): void;
};

const ActivateZone: React.FC<ActivateZoneProps> = (props) => {
  const navigate = useNavigate();
  const zone = props.zone;
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const mapStatus = useJsApiLoader(GlobalConfig.googleMapConfig as any);

  const _onSubmit = async () => {
    try {
      setSubmitting(true);
      const fd = new FormData();
      fd.append('is_active', '1');
      await ZoneService.update(zone.id, fd);
      props.onClose(true);
    } catch(e) {
      setSubmitting(false);
    }
  }

  return (
    <Modal size='sm' isOpen={true} onOpenChange={(isOpen) => {if (!isOpen) {props.onClose()}}}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">Aktifkan Zona</ModalHeader>
        <ModalBody>
          <Text size='xs'>Apakah anda yakin untuk aktifkan zona dibawah ini?</Text>
          <Container>
              <Text size={'small'} weight={'medium'}>Nama</Text>
              <Text>{zone.name}</Text>
          </Container>
          <Container gap={5}>
            <Text size={'small'} weight={'medium'}>Pivot Zonasi</Text>
            {mapStatus.isLoaded ? <GoogleMap mapContainerStyle={{ height: 200, maxWidth: '100%', borderRadius: 10, border: '1px solid #c8c6c4' }}
              zoom={15}
              center={{ lat: Number(zone.latitude), lng: Number(zone.longitude) }} >
              <MarkerF position={{ lat: Number(zone.latitude), lng: Number(zone.longitude) }} />
            </GoogleMap> : null}
          </Container>
          <Container>
              <Text size={'small'} weight={'medium'}>Radius</Text>
              <Text>{zone.radius} km</Text>
          </Container>
          <Container>
              <Text size={'small'} weight={'medium'}>Tipe Kendaraan</Text>
              <Text>{zone.vehicle_category_id}</Text>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onPress={_onSubmit} isLoading={submitting} style={{width: '70%'}}>
            Aktifkan
          </Button>
          <Button isDisabled={submitting} onPress={() => props.onClose()} style={{width: '30%'}}>
            Batal
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

export default ActivateZone;