const GlobalConfig = {
    // apiHostname: "http://localhost:8000",
    // assetHostname: "http://localhost:8000",
    // apiHostname: "https://bancakan.mydrivers.cloud",
    // assetHostname: "https://bancakan.mydrivers.cloud",
    apiHostname: "https://api.happygoapps.my.id",
    assetHostname: "https://api.happygoapps.my.id",
    googleMapConfig: {
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBc-5xCmI11cjzRNx1g6d9VdlC8ZhFuuXo",
        libraries: ['places']
    }
};

export default GlobalConfig;