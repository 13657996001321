import React from 'react';
import styles from './styles.module.scss'
import Container from '../../../components/container';
import Text from '../../../components/text';
import DriverWalletHistory from '../../../components/wallets/list/driver';
import { Button } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-light-svg-icons';

type TypeWalletPageProps = {
  children?: React.ReactNode;
};

const WalletPage: React.FC<TypeWalletPageProps> = (props) => {
  return (
    <Container className={styles.container} gap={20} style={{marginBottom:100}}>
      <Text size={'xl'} weight='bold'>Keuangan</Text>
      <DriverWalletHistory />
    </Container>
  )
};

export default WalletPage;