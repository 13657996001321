import { ICustomerResourceShort } from '../../props/customers';
import Request from './../general/request';

const CustomerService = {
  retrieve: async (qs?: string) => {
    try {
      const response = await Request.get<ICustomerResourceShort[]>(`/api/admin/customers?${qs || ""}`);
      return response.result;
    } catch (error) {
      throw error
    }
  },
  setAsSales: async (customerId: number, data: FormData) => {
    try {
      const response = await Request.post<ICustomerResourceShort>(`/api/admin/customers/${customerId}/is_sales`, data);
      return response.result;
    } catch (error) {
      throw error
    }
  },
  read: async (customerId: string) => {
    try {
      const response = await Request.get<ICustomerResourceShort>(`/api/admin/customers/${customerId}`);
      return response;
    } catch (error) {
      throw error
    }
  },
  update: async (customerId: string, data: FormData) => {
    try {
      const response = await Request.put<ICustomerResourceShort>(`/api/admin/customers/${customerId}`, data);
      return response;
    } catch (error) {
      throw error
    }
  },
  updatePassword: async (customerId: string, data: FormData) => {
    try {
      const response = await Request.post<ICustomerResourceShort>(`/api/admin/customers/${customerId}/update_password`, data);
      return response;
    } catch (error) {
      throw error
    }
  },
  updateQuotaSearch: async (customerId: string, data: FormData) => {
    try {
      const response = await Request.post<ICustomerResourceShort>(`/api/admin/customers/${customerId}/update_quota`, data);
      return response;
    } catch (error) {
      throw error
    }
  },
  updateStatus: async (customerId: string, data: FormData) => {
    try {
      const response = await Request.post<ICustomerResourceShort>(`/api/admin/customers/${customerId}/update_status`, data);
      return response;
    } catch (error) {
      throw error
    }
  },
}

export default CustomerService;