import React, { useEffect } from 'react';
import Text from '../../../text';
import { faCancel, faCheck, faChevronDown, faClock, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { Button, Chip, Divider, Input, Modal, ModalBody, ModalContent, ModalHeader, Select, SelectItem, Spinner } from "@nextui-org/react";
import DropdownButton from '../../../buttons/dropdown';
import Container from '../../../container';
import styles from './styles.module.scss';
import { useJsApiLoader } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import GlobalConfig from '../../../../config';
import { IDriverResourceShort } from '../../../../props/drivers';
import DriverService from '../../../../services/drivers';
import TopUpMethods from '../../../../manifests/topUpMethods';
import Footer from '../../../footer';
import { IDriverWalletHistoryResourceShortProps } from '../../../../props/drivers/walletHistory';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import NumberService from '../../../../services/general/number';
import QRCode from 'react-qr-code';
import GeneralFileService from '../../../../services/general/file';


type DriverVerifyTopUpProps = {
    referenceId: string;
};

const DriverVerifyTopUp: React.FC<DriverVerifyTopUpProps> = (props) => {
    const navigate = useNavigate();

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [driver, setDriver] = React.useState<IDriverResourceShort | undefined>();
    const [history, setHistory] = React.useState<IDriverWalletHistoryResourceShortProps | undefined>();
    const [refreshing, setRefreshing] = React.useState<boolean>(false);
    const [activeModal, setActiveModal] = React.useState<string | undefined>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        await refresh();
        setLoaded(true);
    }

    const refresh = async () => {
        setRefreshing(true);
        const _history = (await DriverService.wallet.topup.read(props.referenceId)).result;
        const _driver = (await DriverService.read(_history.driver_id + "")).result;

        console.log(_history)
        console.log(_driver)

        setHistory(_history);
        setDriver(_driver);
        setRefreshing(false);

        if (_history.status !== 21 && _history.status !== 22) {
            setTimeout(() => {
                refresh();
            }, 10000);
        }
    }

    const renderStatus = (historyType: string, status: number) => {
        let color: "success" | "warning" | "default" | "primary" | "secondary" | "danger" | undefined;
        let type = historyType === 'topup' ? 'Top-up' : 'Tarik Saldo'
        let text: string = type + ' Dibatalkan';
        let icon: IconProp = faCancel;

        console.log(historyType)
        console.log(status)

        if (status === 20) {
            color = 'warning';
            text = 'Menunggu Pembayaran';
            icon = faClock;
        } else if (status === 21) {
            color = 'success';
            text = type + ' Berhasil';
            icon = faCheck;
        } else if (status === 22) {
            color = 'danger';
            text = type + ' Gagal';
            icon = faExclamationTriangle;
        }

        return <Chip color={color}>
            <Container direction={'row'} gap={10} alignItems='center'>
                <FontAwesomeIcon icon={icon} />
                <Text size={'small'}>{text}</Text>
            </Container>
        </Chip>
    }

    const renderPaymentMethod = (_history: IDriverWalletHistoryResourceShortProps) => {
        let channel = '';
        let text = '';

        if ((_history.xendit_channel_mode || "").toLowerCase() === 'id_ovo') {
            channel = 'OVO';
        } else if ((_history.xendit_channel_mode || "").toLowerCase() === 'id_shoppeepay') {
            channel = 'Shoppee Pay'
        } else if ((_history.xendit_channel_mode || "").toLowerCase() === 'qr_code') {
            channel = 'QR Code'
        }

        if (_history.payment_method === 'ewallet') {
            text = "e-wallet - " + channel;
        } else if (_history.payment_method === 'qrcode') {
            text = channel;
        } else if (_history.payment_method === 'transfer') {
            text = 'Transfer'
        } else if (_history.payment_method === 'cash') {
            text = 'Cash'
        }

        return <>
            {_history.status === 20 && _history.payment_method === 'qrcode' && _history.xendit_qr_code ? <Container>
                <Button onPress={() => setActiveModal('qrcode')}>Tampilkan QR Code</Button>
            </Container> : null}
            <Container gap={3}>
                <Text size={'small'} weight='semibold'>Metode Pembayaran</Text>
                <Text size={'small'}>{text}</Text>
            </Container>
            {_history.transaction_proof_url ? <Container style={{gap: 5}}>
                <Text size={'small'} weight='medium'>Bukti Pembayaran</Text>
                <img src={GeneralFileService.getFileUrl("/storage/app/public/"+_history.transaction_proof_url)} style={{maxWidth: 250}} />
            </Container> : null}
        </>
    }

    return <Container className={styles.container} gap={20} style={{
            backgroundColor: 'white',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 0,
            // alignItems: 'center',
            // justifyContent: 'center',
            // height: '80vh',
        }}>
        {!loaded ? <Spinner label='Memuat detail ...' /> : null}
        {loaded && driver && history ? <>
            <Container direction='row' justifyContent='space-between' alignItems='center'>
                <Text size={'xl'} weight='bold'>Nota {history.history_type === 'topup' ? 'Top-up' : 'Penarikan'} Saldo Driver</Text>
            </Container>
            <Container gap={20}>
                {refreshing ? <Spinner label={"Mengecek ulang pembayaran ..."} /> : null}
                {renderStatus(history.history_type, history.status)}
                <Container gap={3}>
                    <Text size={'small'} weight='semibold'>Driver</Text>
                    <Text size={'small'}>{driver.first_name + " " + (driver.last_name || "")}</Text>
                </Container>
                <Container gap={3}>
                    <Text size={'small'} weight='semibold'>Tanggal/Waktu Dibayar</Text>
                    <Text size={'small'}>{moment(history.updated_at).format("DD/MM/YYYY HH:mm:ss")}</Text>
                </Container>
                <Divider />
                {renderPaymentMethod(history)}
                <Container>
                    <Text size={'small'} weight='semibold'>Nominal</Text>
                    <Text size={'small'}>Rp. {NumberService.getNumberWithSeparator(history.amount)}</Text>
                </Container>
            </Container>
            <Modal isOpen={activeModal === 'qrcode' && history.xendit_qr_code !== undefined} onOpenChange={() => setActiveModal(undefined)}>
                <ModalContent>
                    <ModalHeader>
                        QR Code
                    </ModalHeader>
                    <ModalBody>
                        <QRCode size={256} style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={history.xendit_qr_code || ""}
                            viewBox={`0 0 256 256`} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </> : null}
    </Container>
};

export default DriverVerifyTopUp;