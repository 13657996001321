import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Textarea, useDisclosure } from '@nextui-org/react';
import React from 'react';
import Text from '../../text';
import avatar from './../../../assets/avatar/avatar_1.png'
import Container from '../../container';
import { IStationAreaResourceShortProps } from '../../../props/stations/area';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import GlobalConfig from '../../../config';
import StationAreaService from '../../../services/station/area';
import { useNavigate } from 'react-router-dom';

type ActivateAreaProps = {
  children?: React.ReactNode;
  area: IStationAreaResourceShortProps;
  onClose(refresh?: boolean): void;
};

const ActivateArea: React.FC<ActivateAreaProps> = (props) => {
  const navigate = useNavigate();
  const area = props.area;
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const mapStatus = useJsApiLoader(GlobalConfig.googleMapConfig as any);

  const _onSubmit = async () => {
    try {
      setSubmitting(true);
      const fd = new FormData();
      fd.append('is_active', '1');
      await StationAreaService.update(area.id, fd);
      props.onClose(true);
    } catch(e) {
      setSubmitting(false);
    }
  }

  return (
    <Modal size='sm' isOpen={true} onOpenChange={(isOpen) => {if (!isOpen) {props.onClose()}}}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">Aktifkan Area</ModalHeader>
        <ModalBody>
          <Text size='xs'>Apakah anda yakin untuk aktifkan area dibawah ini?</Text>
          <Container>
              <Text size={'small'} >Nama</Text>
              <Text weight={'extrabold'}>{area.name}</Text>
          </Container>
          <Container gap={5}>
            <Text size={'small'} weight={'medium'}>Pivot Area</Text>
            {mapStatus.isLoaded ? <GoogleMap mapContainerStyle={{ height: 200, maxWidth: '100%', borderRadius: 10, border: '1px solid #c8c6c4' }}
              zoom={15}
              center={{ lat: Number(area.latitude), lng: Number(area.longitude) }} >
              <MarkerF position={{ lat: Number(area.latitude), lng: Number(area.longitude) }} />
            </GoogleMap> : null}
          </Container>
          {/* <Container>
              <Text size={'small'} weight={'medium'}>Radius</Text>
              <Text>{area.radius} km</Text>
          </Container> */}
          {/* <Container>
              <Text size={'small'} weight={'medium'}>Tipe Kendaraan</Text>
              <Text>{area}</Text>
          </Container> */}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onPress={_onSubmit} isLoading={submitting} style={{width: '80%'}}>
            Aktifkan
          </Button>
          <Button isDisabled={submitting} onPress={() => props.onClose()} style={{width: '20%'}}>
            Batal
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

export default ActivateArea;