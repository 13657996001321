import React, { useEffect, useState } from 'react';
import Container from '../../container';
import Text from '../../text';
import { Divider, Button } from '@nextui-org/react';
import Footer from '../../footer';
import { IVehicleResourceShortProps } from '../../../props/drivers';
import DriverService from '../../../services/drivers';
import GlobalConfig from '../../../config';
import { useNavigate } from 'react-router-dom';
// import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';

type TypeDriverVehicleVertificationProps = {
  children?: React.ReactNode;
  driverId: string
  vehicleId: string
};

const DriverVehicleVertification: React.FC<TypeDriverVehicleVertificationProps> = (props) => {
  const navigate = useNavigate();
  const [vehicle, setVehicle] = useState<IVehicleResourceShortProps>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    getVehicle()
    return () => { }
  }, [])

  const getVehicle = async () => {
    try {
      const item = await DriverService.vehicle.get(props.driverId, props.vehicleId)
      setVehicle(item)
    } catch (error) {

    }
  }

  interface Vehicle {
    vehicle_certificate: string;
    vehicle_insurance: string;
    vehicle_front_image: string;
    vehicle_side_left_image: string;
    vehicle_side_right_image: string;
    vehicle_back_image: string;
  }
  
  const vehicleImage: Vehicle = {
    vehicle_certificate: GlobalConfig.assetHostname + '/storage/app/public/' + vehicle?.vehicle_certificate,
    vehicle_insurance: GlobalConfig.assetHostname + '/storage/app/public/' + vehicle?.vehicle_insurance ,
    vehicle_front_image: GlobalConfig.assetHostname + '/storage/app/public/' + vehicle?.vehicle_front_image ,
    vehicle_side_left_image: GlobalConfig.assetHostname + '/storage/app/public/' + vehicle?.vehicle_side_left_image ,
    vehicle_side_right_image: GlobalConfig.assetHostname + '/storage/app/public/' + vehicle?.vehicle_side_right_image ,
    vehicle_back_image: GlobalConfig.assetHostname + '/storage/app/public/' + vehicle?.vehicle_back_image ,
  };

  return vehicle ? (
    <Container gap={10} style={{backgroundColor:'#fff', padding:20, borderRadius:0, marginBottom:100}}>
      <Container style={{display:'flex', justifyContent:'start',alignItems:'start'}}>
        <Button onClick={() => {navigate(-1)}} style={{backgroundColor:'#f2f2f2' , borderRadius:100, padding:6, paddingLeft:25, paddingRight:25}} aria-label="Like">
            <FontAwesomeIcon icon={faArrowLeft} /> Kembali
          </Button>
        </Container>
      <Container>
        <Text size='xs'>Tipe Kendaraan</Text>
        <Text size='sm' weight='medium'>{vehicle.vehicle_category.vehicle_type}</Text>
      </Container>
      <Container>
        <Text size='xs'>Merk</Text>
        <Text size='sm' weight='medium'>{vehicle.brand}</Text>
      </Container>
      <Container>
        <Text size='xs'>Model</Text>
        <Text size='sm' weight='medium'>{vehicle.vehicle_name}</Text>
      </Container>
      <Container>
        <Text size='xs'>Warna</Text>
        <Text size='sm' weight='medium'>{vehicle.color}</Text>
      </Container>
      <Container>
        <Text size='xs'>Status</Text>
        <Text style={{ color: vehicle.is_verified ? '#007200' : '#B02E2C' }} size='sm' weight='medium'>{vehicle.is_verified ? 'Sudah Diverifikasi' : 'Belum Diverifikasi'}</Text>
      </Container>
      <Divider />
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.text}>Foto STNK</div>
        <img src={vehicleImage.vehicle_certificate} style={styles.img} alt="stnk" />
      </div>
      <div style={styles.card}>
        <div style={styles.text}>Foto Bukti Asuransi Kendaraan</div>
        <img src={vehicleImage.vehicle_insurance} style={styles.img} alt="insurance" />
      </div>
      <div style={styles.card}>
        <div style={styles.text}>Tampak Depan Kendaraan</div>
        <img src={vehicleImage.vehicle_front_image} style={styles.img} alt="front" />
      </div>
      <div style={styles.card}>
        <div style={styles.text}>Tampak Samping Kiri Kendaraan</div>
        <img src={vehicleImage.vehicle_side_left_image} style={styles.img} alt="side left" />
      </div>
      <div style={styles.card}>
        <div style={styles.text}>Tampak Samping Kanan Kendaraan</div>
        <img src={vehicleImage.vehicle_side_right_image} style={styles.img} alt="side right" />
      </div>
      <div style={styles.card}>
        <div style={styles.text}>Tampak Belakang Kendaraan</div>
        <img src={vehicleImage.vehicle_back_image} style={styles.img} alt="back" />
      </div>
    </div>
      <Footer buttons={[
        {
          key: 'cancel',
          width: '50%',
          color: 'danger',
          children: "Tolak",
          isLoading: submitting,
          onClick: async () => { 
            setSubmitting(true);
            await DriverService.vehicle.reject(vehicle.id + "");
            navigate("/drivers/" + vehicle.driver_id);
          }
        },
        {
          key: 'create',
          color: 'success',
          width: '50%',
          children: "Verifikasi Kendaraan Ini",
          isLoading: submitting,
          onClick: async () => { 
            setSubmitting(true);
            await DriverService.vehicle.accept(vehicle.id + "");
            navigate("/drivers/" + vehicle.driver_id);
          }
        }
        
      ]} />
    </Container>
  ) : <></>
};

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
    gap: '20px',
    justifyContent: 'center' as 'center',
  },
  card: {
    flex: '1 1 calc(33.333% - 20px)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    textAlign: 'center' as 'center',
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
    display: 'block',
    margin: '0 auto',
  },
  text: {
    fontSize: 'small',
    fontWeight: 'semibold' as 'semibold',
    marginTop: '10px',
  },
  '@media (maxWidth: 768px)': {
    card: {
      flex: '1 1 100%',
    },
  },
};

export default DriverVehicleVertification;