import React, { useEffect } from 'react';
import Container from '../container';
import Text from '../text';
import styles from './styles.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faGameConsoleHandheld, faSearch } from '@fortawesome/pro-light-svg-icons';
import { Chip, Input,Button, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, Modal, ModalBody, ModalContent, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import { ICustomerResourceShort } from '../../props/customers';
import { ICustomerResourceShortExport } from '../../props/customers/list_export';
import CustomerService from '../../services/customers';
import DropdownButton, { DropdownButtonSectionProps } from '../buttons/dropdown';
import { NavLink, useNavigate } from 'react-router-dom';

import * as XLSX from 'xlsx';

import { ExportAsExcel, ExportAsPdf, CopyToClipboard, CopyTextToClipboard, PrintDocument, ExcelToJsonConverter, FileUpload } from "react-export-table";


type TypeListCustomerProps = {
  children?: React.ReactNode;
};

const ListCustomer: React.FC<TypeListCustomerProps> = (props) => {
  const navigate = useNavigate();

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [customers, setCustomers] = React.useState<ICustomerResourceShort[]>([]);
  const [customersExport, setCustomersExport] = React.useState<ICustomerResourceShortExport[]>([]);
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>();

 
  let tableData = [
    ['', '', '']
  ];

  useEffect(() => {
    init();
    return () => { }
  }, [search]);

  // const init = async () => {
  //   const _customers = (await CustomerService.retrieve());
  //   setCustomers(_customers);
  //   setLoaded(true);
  // }

  const init = async () => {

    const qs: string[] = [];
		if (search && search.trim() !== '') { qs.push("search=" + search); }
    try {
      tableData = [
      ['', '', '']
      ];
			const items = await CustomerService.retrieve(qs.join(';'))
			setCustomers(items);
      setCustomersExport([]);
			setLoaded(true);
      setCustomersExport(items);

		} catch (error) {

		}
  }

  const jsonResult = customersExport.map((item, index) => {
    return {
      No: index+1,
      Nama: item.first_name+" "+item.last_name,
      'Phone Number': item.phone_number,
      'Email': item.email,
      'Sales?' : item.is_sales ? 'Sales': 'User',
      'Status' : item.status ? 'Aktif': 'Tidak Aktif',
    };
  });

  const exportToExcel = (jsonResult: any[]) => {
    // Convert data to worksheet
    const ws = XLSX.utils.json_to_sheet(jsonResult);
  
    // Auto-width columns
    const colWidth = jsonResult.reduce((acc, data) => {
      return Object.keys(data).reduce((acc, key) => {
        const cellLength = String(data[key]).length;
        return { ...acc, [key]: Math.max(acc[key] || 0, cellLength) };
      }, acc);
    }, {});
  
    const cols = Object.keys(colWidth).map(key => ({ wch: colWidth[key] + 2 })); // Add extra space
  
    ws['!cols'] = cols;
  
    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Save the workbook to a file
    XLSX.writeFile(wb, 'Export Data Sales.xlsx');
  };
	const actions: DropdownButtonSectionProps[] = [
		{
			key: 'details',
			showDivider: false,
			items: [
				{ key: 'setAsSales', text: 'Jadikan sales ON/OFF', onPress: (customer: ICustomerResourceShort) => {
            setUpdating(true);
            setAsSales(customer);
          }
        },
        { key: 'view', text: 'Lihat detail', onPress: (customer: ICustomerResourceShort) => { navigate(`/customers/details/${customer.id}`); } },
			]
		}
	];
	
  const actions2: DropdownButtonSectionProps[] = [
		{
			key: 'details',
			showDivider: false,
			items: [
				{ key: 'view', text: 'Export Data', onPress: (customer: ICustomerResourceShort) => { navigate(`/customers/details/${customer.id}`); } },
			]
		}
	];

  const setAsSales = async (customer: ICustomerResourceShort) => {
    const fd = new FormData
    fd.append('customer_id', customer.id + "");

    if(customer.is_sales){
      fd.append('is_sales', "0")
    }else{
      fd.append('is_sales', "1")
    }
    await CustomerService.setAsSales((customer.id), fd)
    setUpdating(false);
    setLoaded(false);
    init();
    
  }
  const handleClick = () => {
    console.log(jsonResult);
    exportToExcel(jsonResult);
  };
  

  return (
    
    <Container gap={20} className={styles.container}>
      <Button color='success' onClick={handleClick}>Export Pelanggan</Button>
      <Container direction='row' gap={10} style={{ zIndex: 1 }}>
      
		</Container>
      <Table  aria-label="Daftar area" isStriped>
        <TableHeader>
          <TableColumn>No</TableColumn>
          <TableColumn>Nama</TableColumn>
          <TableColumn>No Hp</TableColumn>
          <TableColumn>Email</TableColumn>
          <TableColumn>Sales ?</TableColumn>
          <TableColumn>Status</TableColumn>
        </TableHeader>
        <TableBody isLoading={!loaded} loadingContent={<Spinner />} emptyContent={<Text>Belum ada customer terdaftar</Text>}>
          {customers.map((customer, index) => {
            return <TableRow key={customer.id}>
              <TableCell>
                <Text size='base' weight='medium'>
                  {index+1}
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                  {customer.first_name} {customer.last_name || ""} 
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                  {customer.phone_with_code} 
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                  {customer.email}
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                  {customer.is_sales ? <Chip color={'primary'}>Sales</Chip> : <Chip color={'danger'}>User</Chip>}
                </Text>
              </TableCell>
              <TableCell>
                <Text size='base' weight='medium'>
                {customer.status ? <Chip color={'primary'}>Aktif</Chip> : <Chip color={'danger'}>OFF</Chip>}
                </Text>
              </TableCell>
              
            </TableRow>;
          })}
        </TableBody>
      </Table>
      <Modal isOpen={updating}>
        <ModalContent>
          <ModalBody>
            <Spinner>Harap tunggu ...</Spinner>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default ListCustomer;