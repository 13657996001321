import React, { useEffect } from 'react';
import Text from '../../text';
import { faArrowLeft, faCalendar, faCheckCircle, faChevronDown, faEdit, faEnvelope, faEye, faFileImage, faHome, faIdCard, faImageUser, faJoint, faMailbox, faPhone, faWallet } from '@fortawesome/pro-light-svg-icons';
import { Spinner, Divider,Button, Chip } from "@nextui-org/react";
import DropdownButton, { DropdownButtonSectionProps } from '../../buttons/dropdown';
import Container from '../../container';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import avatar from '../../../assets/avatar/avatar_1.png'
import { IDriverResourceShort } from '../../../props/drivers';
import DriverVehicleList from '../vehicle/list';
import UpdatePasswordModalArea from '../modals/changePassword';
import UpdateProfPictModalArea from '../modals/changeDataProfPict';
import UpdateDataModalArea from '../modals/changeData';
import DriverService from '../../../services/drivers';
import moment from 'moment';
import GlobalConfig from '../../../config';
import GeneralFileService from '../../../services/general/file';
import NumberService from '../../../services/general/number';
// import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


type DriverDetailsProps = {
  driverId: string;
};

const DriverDetails: React.FC<DriverDetailsProps> = (props) => {
  const navigate = useNavigate();

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [driver, setDriver] = React.useState<IDriverResourceShort | undefined>();
  const [activeModal, setActiveModal] = React.useState<'updatePassword' | undefined>();
  const [activeModal2, setActiveModal2] = React.useState<'updateProfPict' | undefined>();
  const [activeModalEdit, setActiveModalEdit] = React.useState<'updateData' | undefined>();


  const getActions = (): DropdownButtonSectionProps[] => {
    const actions: DropdownButtonSectionProps[] = [
      {
        key: 'details',
        showDivider: false,
        items: [
          // { key: 'editData', text: 'Edit Data', onPress: () => { setActiveModalEdit('updateData') } },
          { key: 'topup', text: 'Top-up saldo', onPress: () => { navigate(`/drivers/${driver?.id}/topup/request`); } },
          { key: 'withdraw', text: 'Tarik saldo', onPress: () => { navigate(`/drivers/${driver?.id}/withdraw`); } },
          { key: 'changePassword', text: 'Update password', onPress: () => { setActiveModal('updatePassword') } },
          // { key: 'changeProfPict', text: 'Update Foto', onPress: () => { setActiveModal('updatePassword') } },
        ]
      },
    ];

    if (driver && !driver.is_id_proof_approved) {
      actions[0].items.unshift({
        key: 'vertification', text: 'Verifikasi Dokumen', onPress: () => {
          navigate(`/drivers/${props.driverId}/vertification`)
        }
      });
    }

    return actions;
  }

  useEffect(() => {
    getDriver()
    return () => {

    }
  }, [activeModalEdit])

  const getDriver = async () => {
    try {
      const _driver = (await DriverService.read(props.driverId)).result;
      setDriver(_driver)
      setLoaded(true)
    } catch (error) {

    }
  }

  return <Container className={styles.container} gap={20} style={{
      backgroundColor: 'white',
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 0,
      // alignItems: 'center',
      // justifyContent: 'center',
      // height: '100vh',
    }}>
    {!loaded ? <Spinner label='Memuat detail ...' /> : null}
    {loaded && driver ? <>

      <Container style={{display:'flex', justifyContent:'start',alignItems:'start'}}>
        <Button onClick={() => {navigate(-1)}} style={{backgroundColor:'#f2f2f2' , borderRadius:100, padding:6, paddingLeft:25, paddingRight:25}} aria-label="Like">
            <FontAwesomeIcon icon={faArrowLeft} /> Kembali
          </Button>
        </Container>
      <Container direction='row' justifyContent='space-between' alignItems='center'>

        <Container direction='row' alignItems='center' gap={10}>
          <img
            src={GeneralFileService.getFileUrl("app/public/"+driver.profile_picture)}
            alt="profile"
            style={{ height: 40, width: 40, borderRadius: 100 }} />
          <Container>
            <Text weight={'bold'} style={{fontSize:30, fontWeight:'bolder'}}>{driver.first_name} {driver.last_name||""}</Text>
            {/* <Text size={'small'}>{Number(driver.gender) === 1 ? 'Laki-laki' : 'Perempuan'}</Text> */}
            <Text style={{paddingTop:10, paddingBottom:10}}>{driver?.verification_status?.name==='Approved' ? <Chip style={{color:'#fff'}} color={'success'}>{driver?.verification_status?.name}</Chip> : <Chip color={'danger'}>{driver?.verification_status?.name}</Chip>}</Text>
          </Container>
          
        </Container>
        
        <Container style={{display:'flex', flexDirection:'row', gap:5}}>
        <Button isIconOnly style={{backgroundColor:'#ef4727',color:'#fff'}} onPress={()=>setActiveModalEdit('updateData')}>
            <FontAwesomeIcon icon={faEdit} size='sm' color='#fff'/>
        </Button>
        <Button isIconOnly style={{backgroundColor:'#2479e0',color:'#fff'}} onPress={()=>setActiveModal2('updateProfPict')}>
            <FontAwesomeIcon icon={faImageUser} size='sm' color='#fff'/>
        </Button>
        <DropdownButton
          // data={driver}
          sections={getActions()} backdrop='blur' icon={faChevronDown} />
          </Container>
      </Container>
      <Container gap={20}>
        
      

      {/* <Divider /> */}
        <Container style={{display:'flex', gap:25, flexDirection:'row', padding:20, borderRadius:8, backgroundColor:'#f2f2f2'}}>
          <Container style={{width:'100'}}>
            <FontAwesomeIcon icon={faWallet} size='3x'/>
          </Container>
          <Container>
            <Text size={'small'} weight={'medium'}>Saldo</Text>
            <Text size='large' style={{fontWeight:'bold'}}>Rp. {NumberService.getNumberWithSeparator(driver?.wallet || '0')}</Text>
          </Container>
          
        </Container>
      {/* <Divider /> */}

        <Container style={{display:'flex', gap:25, flexDirection:'row', padding:20, borderRadius:8, backgroundColor:'#f2f2f2'}}>
          <Container style={{width:'100'}}>
            <FontAwesomeIcon icon={faEnvelope} size='3x'/>
          </Container>
          <Container>
            <Text size={'small'} weight={'medium'}>Alamat Email</Text>
            <Text size='large' style={{fontWeight:'bold'}}>{driver.email}</Text>
          </Container>
          
        </Container>
        <Container style={{display:'flex', gap:25, flexDirection:'row', padding:20, borderRadius:8, backgroundColor:'#f2f2f2'}}>
          <Container style={{width:'100'}}>
            <FontAwesomeIcon icon={faPhone} size='3x'/>
          </Container>
          <Container>
            <Text size={'small'} weight={'medium'}>Kontak</Text>
            <Text size='large' style={{fontWeight:'bold'}}>{driver.phone_with_code}</Text>
          </Container>
          
        </Container>
        <Container style={{display:'flex', gap:25, flexDirection:'row', padding:20, borderRadius:8, backgroundColor:'#f2f2f2'}}>
          <Container style={{width:'100'}}>
            <FontAwesomeIcon icon={faCheckCircle} size='3x'/>
          </Container>
          <Container>
            <Text size={'small'} weight={'medium'}>Bergabung</Text>
            <Text size='large' style={{fontWeight:'bold'}}>{moment(driver.created_at).format('DD MMMM YYYY')}</Text>
          </Container>
          
        </Container>
        <Container style={{display:'flex', gap:25, flexDirection:'row', padding:20, borderRadius:8, backgroundColor:'#f2f2f2'}}>
          <Container style={{width:'100'}}>
            <FontAwesomeIcon icon={faHome} size='3x'/>
          </Container>
          <Container>
            <Text size={'small'} weight={'medium'}>Alamat</Text>
            <Text size='large' style={{fontWeight:'bold'}}>{driver?.address || '-'}</Text>
          </Container>
          
        </Container>
        <Container style={{display:'flex', gap:25, flexDirection:'row', padding:20, borderRadius:8, backgroundColor:'#f2f2f2'}}>
          <Container style={{width:'100'}}>
            <FontAwesomeIcon icon={faCalendar} size='3x'/>
          </Container>
          <Container>
            <Text size={'small'} weight={'medium'}>Tgl. Lahir</Text>
            <Text size='large' style={{fontWeight:'bold'}}>{moment(driver.date_of_birth).format('DD MMMM YYYY')}</Text>
          </Container>
          
        </Container>
        <Container style={{display:'flex', gap:25, flexDirection:'row', padding:20, borderRadius:8, backgroundColor:'#f2f2f2'}}>
          <Container style={{width:'100'}}>
            <FontAwesomeIcon icon={faIdCard} size='3x'/>
          </Container>
          <Container style={{gap:10}}>
            <Text size={'small'} weight={'medium'}>Tanda Pengenal Driver</Text>
            <img src={GeneralFileService.getFileUrl("/app/public/"+driver.id_proof)} alt="stnk" style={{maxWidth: '300px'}} />
          </Container>
          
        </Container>
        
      </Container>
      
      <Divider />
      <Container>
        <Text size='large'>Daftar Kendaraan</Text>
      <DriverVehicleList driverId={props.driverId} />
      </Container>
    </> : null}
    {activeModal && activeModal === 'updatePassword' && driver && <UpdatePasswordModalArea
      onClose={function (refresh?: boolean | undefined): void {
        setActiveModal(undefined)
      }}
      driver={driver} />}
    {activeModal2 && activeModal2 === 'updateProfPict' && driver && <UpdateProfPictModalArea
      onClose={function (refresh?: boolean | undefined): void {
        setActiveModal2(undefined)
      }}
      driver={driver} />}
    {activeModalEdit && activeModalEdit === 'updateData' && driver && <UpdateDataModalArea
    onClose={function (refresh?: boolean | undefined): void {
      setActiveModalEdit(undefined)
    }}
    driver={driver} />}
  </Container>
};

export default DriverDetails;