import React, { useState } from 'react';
import Container from '../../../components/container';
import Text from '../../../components/text';
import styles from './styles.module.scss'
import { Button, Input } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';
import ListCustomer from '../../../components/customers/list';
import ListAdmin from '../../../components/admins/list';
import { NavLink } from 'react-router-dom';
import { auto } from '@popperjs/core';

type TypeAdminsPageProps = {
  children?: React.ReactNode;
};

const AdminsPage: React.FC<TypeAdminsPageProps> = (props) => {
  const [search, setSearch] = useState<string | undefined>()
  return (
    <Container className={styles.container} gap={20} style={{marginBottom:100}}>
      <Text size={'xl'} weight='bold'>Root Access</Text>
      <Container direction='row' gap={10} style={{ zIndex: 1,width:'100%',display:'flex', alignItems:'center', justifyContent:'flex-end'}} alignItems='center'>
        <Input
          value={search}
          placeholder="Cari root access ..."
          labelPlacement="inside"
          size='sm'
          color='warning'
          startContent={<FontAwesomeIcon icon={faSearch}/>}
          onChange={(e) => setSearch(e.target.value)}
          // endContent={
          //   <FontAwesomeIcon icon={faSearch} size='1x' />
          // }
          style={{ zIndex: 1}}
        />
        <NavLink to={"/admins/create"}>
          <Button color='primary' style={{backgroundColor:'#ef4727'}}>
            <FontAwesomeIcon icon={faPlus} />Tambah
          </Button>
        </NavLink>
      </Container>
      <ListAdmin search={search} />
    </Container>
  )
};

export default AdminsPage;